import { AbsoluteRoutes, NotificationType, RequestState } from '../../../../../../utils/enums';
import { UrlsType, getById } from '../../../../../../utils/services';
import { useCallback, useEffect, useState } from 'react';

import type { TAnyOneEventResponse } from '../../../../shared/types';
import { notificationSelector } from '../../../../../../utils/selectors';
import { tokenState } from '../../../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useGetDbInitialValues({
  requestKey,
  eventId,
}: {
  requestKey: UrlsType;
  eventId: number;
}): [RequestState, TAnyOneEventResponse | null] {
  const [token] = useRecoilState(tokenState);
  const [, addNotification] = useRecoilState(notificationSelector);
  const [requestStatus, setRequestStatus] = useState<RequestState>(RequestState.Idle);
  const [fetchedEventsData, setFetchedEventsData] = useState<TAnyOneEventResponse | null>(null);
  const { t } = useTranslation();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const isMounted = useIsMounted();
  const history = useHistory();

  const fetchEventsData = useCallback(async () => {
    setRequestStatus(RequestState.Pending);
    if (!token) {
      addNotification({
        title: t('appErrorNotification.titleBadCredentials'),
        text: t('appErrorNotification.noTokenText'),
        type: NotificationType.Error,
      });
      setRequestStatus(RequestState.Error);
      return;
    }
    const { data, error } = await getById(requestKey, eventId, token);

    if (!isMounted()) return;

    if (error) {
      if (error?.response?.status === 404) {
        history.push(AbsoluteRoutes.DashboardMain);
        return;
      }
      addNotification({
        title: t('appErrorNotification.titleEvent'),
        text: t('appErrorNotification.eventForm'),
        type: NotificationType.Error,
      });
      setRequestStatus(RequestState.Error);
      fetchingErrorHandler(error);
      return;
    }

    setFetchedEventsData({
      ...(data as TAnyOneEventResponse),
      event_date: (data as TAnyOneEventResponse).event_date,
    } as TAnyOneEventResponse);
    setRequestStatus(RequestState.Success);
  }, [addNotification, eventId, fetchingErrorHandler, history, isMounted, requestKey, t, token]);

  useEffect(() => {
    fetchEventsData();
  }, [fetchEventsData]);

  return [requestStatus, fetchedEventsData];
}
