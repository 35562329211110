import { LocalStorageKeys, NotificationType, RequestState } from '../../../../../utils/enums';

import { AppModules } from '../../../../../utils/const';
import { AuthRoutePath } from '../../utils/const/route-path';
import { LocalStorageService } from '../../../../../utils/services';
import type { TVerifyPhoneForm } from '../../types/types';
import { notificationSelector } from '../../../../../utils/selectors';
import { useCallback } from 'react';
import { useFetchingErrorHandler } from '../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router-dom';
import { usePhoneVerify } from '../../utils/hooks/usePhoneVerify';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useHandlers(): { requestState: RequestState; handleSubmit: (data: TVerifyPhoneForm) => void } {
  const access = LocalStorageService.getItem(LocalStorageKeys.Access) || '';
  const history = useHistory();
  const [requestState, onPhoneVerify] = usePhoneVerify();
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const { fetchingErrorHandler } = useFetchingErrorHandler();

  const handleSubmit = useCallback(
    async ({ phone, code, ...data }: TVerifyPhoneForm): Promise<void> => {
      const response = await onPhoneVerify({
        phone_number: `+${code}${phone}`,
        recaptcha_token: data.recaptcha_token,
        access: access,
        language: code === `380` ? 'uk' : 'en',
      });

      if (!!response.error) {
        if (!response.error.response)
          fetchingErrorHandler({
            ...response.error,
            response: { status: 504 },
          });

        switch (response.error.response.status) {
          case 401:
            addNotification({
              title: t('appErrorNotification.titleBadCredentials'),
              text: t('appErrorNotification.badCredentials'),
              type: NotificationType.Error,
            });
            break;
          case 400:
            addNotification({
              title: t('appErrorNotification.titleBadCredentials'),
              text: t('appErrorNotification.invalidData'),
              type: NotificationType.Error,
            });
            break;
          case 499:
            addNotification({
              title: t('appErrorNotification.technicalIssues'),
              text: t('appErrorNotification.tryLater'),
              type: NotificationType.Error,
            });
            break;
          default:
            fetchingErrorHandler(response.error);
        }
      } else {
        LocalStorageService.setItem(LocalStorageKeys.Code, code);
        LocalStorageService.setItem(LocalStorageKeys.Phone, phone);
        LocalStorageService.setItem(LocalStorageKeys.PhoneNumber, `+${code}${phone}`);
        history.push(`/${AppModules.auth}/${AuthRoutePath.CODE_VERIFY}`);
      }
    },
    [access, addNotification, fetchingErrorHandler, history, onPhoneVerify, t],
  );

  return { requestState, handleSubmit };
}
