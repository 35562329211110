import { useCallback, useEffect, useState } from 'react';

import type { TUseContentProps } from '../../../../shared/types';
import { throttle } from 'lodash';
import { useInView } from 'react-intersection-observer';

const inViewProps = {
  threshold: 0.5,
  rootMargin: '0px 0px -100px 0px', // rootMargin must be in px or %
};

export function useContentData({
  pageRef,
  needToFetchMore,
  loadMoreFeeds,
  setOpacity,
  refreshFeed,
  feeds,
}: TUseContentProps) {
  const [showArrow, setShowArrow] = useState<boolean>(false);
  const { ref: lastRef, inView: lastInView } = useInView(inViewProps);
  const { ref: firstRef, inView: firstInView } = useInView(inViewProps);
  const [loaderState, setLoaderState] = useState<{
    startPosition: number;
    currentPosition: number;
    height: number;
  } | null>(null);
  const currentPage = pageRef.current;

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleStartMove = useCallback((event: TouchEvent) => {
    if (window.scrollY !== 0) return;
    const targetElement = event.target as HTMLElement;
    const needToPreventClick = !targetElement.closest('.MuiCard-root');
    if (needToPreventClick) return;
    const currentPosition = event.touches[0].clientY;
    setLoaderState(prevValues => {
      return !!prevValues
        ? { ...prevValues, startPosition: currentPosition }
        : { startPosition: currentPosition, currentPosition: 0, height: 0 };
    });
  }, []);

  const handleTouchMove = throttle((event: TouchEvent) => {
    setLoaderState(prevValues => {
      if (!prevValues) return null;
      const currentPosition = event.touches[0].clientY;
      const difference =
        prevValues.startPosition < currentPosition ? Math.abs(prevValues.startPosition - currentPosition) : 0;
      return { ...prevValues, height: Math.min(32, difference) };
    });
  }, 100);

  const handleEndMove = useCallback(
    (event: TouchEvent) => {
      if (!loaderState) return;
      loaderState.height === 32 ? refreshFeed() : setLoaderState(null);
    },
    [loaderState, refreshFeed],
  );

  useEffect(() => {
    lastInView && needToFetchMore && loadMoreFeeds();
    setOpacity(!!lastInView ? (needToFetchMore ? 1 : 0) : 1);
  }, [lastInView, loadMoreFeeds, needToFetchMore, setOpacity]);

  useEffect(() => {
    feeds.length > 1 && setShowArrow(!firstInView);
  }, [feeds.length, firstInView]);

  useEffect(() => {
    const optimizedHandleStartMove = (event: TouchEvent) => requestAnimationFrame(() => handleStartMove(event));
    const optimizedHandleTouchMove = (event: TouchEvent) => requestAnimationFrame(() => handleTouchMove(event));
    const optimizedHandleEndMove = (event: TouchEvent) => requestAnimationFrame(() => handleEndMove(event));

    currentPage?.addEventListener('touchstart', optimizedHandleStartMove);
    currentPage?.addEventListener('touchmove', optimizedHandleTouchMove);
    currentPage?.addEventListener('touchend', optimizedHandleEndMove);
    return () => {
      currentPage?.removeEventListener('touchstart', optimizedHandleStartMove);
      currentPage?.removeEventListener('touchmove', optimizedHandleTouchMove);
      currentPage?.removeEventListener('touchend', optimizedHandleEndMove);
    };
  }, [currentPage, handleStartMove, handleTouchMove, handleEndMove]);

  return { loaderState, firstRef, lastRef, showArrow, scrollToTop };
}
