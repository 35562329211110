import './PetForm.scss';

import { AbsoluteRoutes, RequestState, SelectTypes } from '../../../../../utils/enums';
import {
  AppHeader,
  CustomDateInput,
  CustomInput,
  CustomSimpleSelect,
  FormAvatar,
  FormOutdoorSchedule,
  PageLoader,
  RadioButtonGroup,
  WithFooterMenu,
} from '../../../shared';
import { FastField, Field, Form, useFormikContext } from 'formik';
import type { TPetForm, TPropsType } from './types/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useData, useFetchKind, usePetForm } from '../../hooks';

import type { CalendarDate } from '../../../../../utils/types';
import { CustomSearchSelect } from '../../../shared/components/CustomSearchSelect/CustomSearchSelect';
import { EN_LOCALE_DATE_FORMAT_DAYJS } from '../../../../../utils/const';
import { PetFormFields } from '../../utils/enums/formFields';
import default_pet_avatar from '../../../../../assets/images/pet/default_pet_avatar.svg';
import { isEqual } from 'lodash';
import saveIcon from '../../../../../assets/images/save_icon.svg';
import { useHandleHideErrors } from '../../../../../utils/hooks/useHandleHideErrors';
import { usePopUpHandlers } from '../../../shared/hooks';
import { useTranslation } from 'react-i18next';

export const PetForm = ({ titleI18Key }: TPropsType) => {
  const { values, handleClearField, onIdTypeChange } = usePetForm();
  const [popUpShow, setPopUpShow] = useState(false);
  const { kinds, requestStatus } = useFetchKind();

  const { t } = useTranslation();

  const { errors, touched, initialValues, setFieldValue, isSubmitting, setErrors, handleSubmit } =
    useFormikContext<TPetForm>();
  const { handleHideErrors } = useHandleHideErrors<TPetForm>({ errors, setErrors });
  const { handleBackButtonClick } = usePopUpHandlers({ popUpShow, prevUrl: AbsoluteRoutes.AddPet });

  const handleDateChange = useCallback(
    (date: CalendarDate) => {
      if (date) {
        setFieldValue(PetFormFields.BIRTH, date.format(EN_LOCALE_DATE_FORMAT_DAYJS));
      }
    },
    [setFieldValue],
  );

  const {
    selectLivingPlaceOptions,
    selectTemperamentOptions,
    genderOptions,
    foodOptions,
    sterilizationOptions,
    animalIdOptions,
    setFetchBreedsArgs,
    breeds,
    isLoading,
  } = useData();

  useEffect(() => {
    const newFetchBreedsArgs = { kindId: values.kind };
    setFetchBreedsArgs(newFetchBreedsArgs);
    setFieldValue(PetFormFields.BREED, undefined);
  }, [setFetchBreedsArgs, setFieldValue, values.kind]);

  useMemo(() => {
    setPopUpShow(!isEqual(values, initialValues));
  }, [initialValues, values]);

  return (
    <WithFooterMenu popUpShow={popUpShow}>
      <Form noValidate>
        {(isSubmitting || isLoading || requestStatus === RequestState.Pending) && <PageLoader />}
        <AppHeader goBack={handleBackButtonClick} title={t(titleI18Key)} icon={saveIcon} iconHandler={handleSubmit} />
        <div className='petPageFormForm'>
          <div className='petPageFormFormItem'>
            <div className='petPageFormAvatarContainer'>
              <FastField name={PetFormFields.AVATAR} component={FormAvatar} defaultSrc={default_pet_avatar} alt='pet' />
            </div>
          </div>
          <div className='petPageFormFormItem'>
            <Field
              name={PetFormFields.KIND}
              component={CustomSimpleSelect}
              required
              defaultValue=''
              errors={touched.kind && errors.kind}
              label={t('petForm.placeholders.kind')}
              errorType='transparent'
              selectType={SelectTypes.PopUpSelect}
              choices={kinds}
              handleHideErrors={handleHideErrors}
            />
          </div>
          <div className='petPageFormFormItemName'>
            <FastField
              label={t('petForm.placeholders.name')}
              name={PetFormFields.NAME}
              id={PetFormFields.NAME}
              clear={handleClearField}
              component={CustomInput}
              errors={touched.name && errors.name}
              errorType='transparent'
              handleHideErrors={handleHideErrors}
              required
            />
          </div>
          <div className='petPageFormFormItemBirth'>
            <FastField
              name={PetFormFields.BIRTH}
              id={PetFormFields.BIRTH}
              component={CustomDateInput}
              minDateSelector={30}
              maxDate={0}
              required
              label={t('petForm.placeholders.birthday')}
              errors={touched.birth && errors.birth}
              errorType='transparent'
              onChange={handleDateChange}
              value={values.birth}
              handleHideErrors={handleHideErrors}
            />
          </div>
          <div className='petPageFormFormItem'>
            <Field
              key={values.kind}
              name={PetFormFields.BREED}
              component={CustomSearchSelect}
              label={t('petForm.placeholders.breed')}
              title={t('petForm.placeholders.breed')}
              options={breeds}
              value={values.breed}
              initialValue={initialValues.breed}
            />
          </div>
          <div className='petPageFormFormItem'>
            <FastField
              name={PetFormFields.GENDER}
              label={t('petForm.placeholders.gender')}
              options={genderOptions}
              component={RadioButtonGroup}
            />
          </div>
          <div className='petPageFormFormItem'>
            <FastField
              label={t('petForm.placeholders.color')}
              name={PetFormFields.COLOR}
              id={PetFormFields.COLOR}
              component={CustomInput}
              errorType='transparent'
              clear={handleClearField}
              errors={touched.color && errors.color}
              handleHideErrors={handleHideErrors}
            />
          </div>
          <div className='petPageFormFormItem'>
            <FastField
              label={t('petForm.placeholders.special_signs')}
              name={PetFormFields.SPECIAL_SIGNS}
              id={PetFormFields.SPECIAL_SIGNS}
              component={CustomInput}
              clear={handleClearField}
              errorType='transparent'
              errors={touched.special_signs && errors.special_signs}
              handleHideErrors={handleHideErrors}
            />
          </div>
          <div className='petPageFormFormItem'>
            <FastField
              name={PetFormFields.FOOD}
              label={t('petForm.placeholders.food')}
              options={foodOptions}
              component={RadioButtonGroup}
            />
          </div>
          <div className='petPageFormFormItem'>
            <FastField
              name={PetFormFields.STERILIZATION}
              label={t('petForm.placeholders.sterilization')}
              options={sterilizationOptions}
              component={RadioButtonGroup}
            />
          </div>
          <div className='petPageFormFormItem'>
            <FastField
              name={PetFormFields.LIVING_PLACE}
              component={CustomSimpleSelect}
              choices={selectLivingPlaceOptions}
              label={t('petForm.placeholders.living_place')}
            />
          </div>
          <div className='petPageFormFormItem'>
            <FastField
              name={PetFormFields.OUTDOOR_SCHEDULE}
              component={FormOutdoorSchedule}
              label={t('petForm.placeholders.outdoor_schedule')}
            />
          </div>
          <div className='petPageFormFormItem'>
            <Field
              id={PetFormFields.ID_CHIP_STIGMA}
              name={PetFormFields.ID_TYPE}
              label={t('petForm.placeholders.animal_id')}
              options={animalIdOptions}
              component={RadioButtonGroup}
              onChange={onIdTypeChange}
              isInColumn
              handleHideErrors={handleHideErrors}
            />
          </div>
          <div className='petPageFormFormItem'>
            <FastField
              name={PetFormFields.TEMPERAMENT}
              component={CustomSimpleSelect}
              choices={selectTemperamentOptions}
              label={t('petForm.placeholders.temperament')}
            />
          </div>
        </div>
      </Form>
    </WithFooterMenu>
  );
};
