import {
  CompressorConvertSize,
  CompressorСonvertTypesArr as CompressorConvertTypesArr,
  imageMaxSize,
} from '../../../../utils/const';
import { type MouseEvent, useCallback, useState } from 'react';
import { LocalStorageKeys, NotificationType, RequestState } from '../../../../utils/enums';
import { blobToFile, checkImage } from '../../../../utils/helpers';
import { LocalStorageService, post, remove } from '../../../../utils/services';

import Compressor from 'compressorjs';
import type { TProps } from '../../pet/components/PetGallery/types';
import deleteIcon from '../../../../assets/images/dashboard/delete_pet_icon.svg';
import { notificationSelector } from '../../../../utils/selectors';
import { popupState } from '../components/Popup/utils/atoms/popup';
import { theme } from '../../../../MUITheme';
import { tokenState, userRoleState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../utils/hooks';
import { useParams } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { usePostShare } from './usePostShare';

export function useSliderHandlers({
  inputRef,
  onImageUpload,
  setCurrentPage,
  setPrevPage,
  setCurrentImage,
  isDefault,
  removeImage,
  sliderRef,
  deleteUrl,
  items,
  slidesToShow,
  isItFeed,
  setGalleryItems,
  eventId,
  setImage,
  editorRef,
}: TProps) {
  const [token] = useRecoilState(tokenState);
  const params = useParams<{ id: string; petId: string }>();
  const petId = params.id;
  const [updateStatus, setUpdateStatus] = useState(RequestState.Idle);
  const [clickStart, setClickStart] = useState(0);
  const isMounted = useIsMounted();
  const [, addNotification] = useRecoilState(notificationSelector);
  const [, popupStateUpdate] = useRecoilState(popupState);
  const userRole = useRecoilValue(userRoleState);
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const { t } = useTranslation();
  const { postShare } = usePostShare();

  const currentSlider = sliderRef.current;

  const handleImageClick = useCallback(
    ({ hasAddIcon, itemIndex, url, imageId, itemsCount }) => {
      if (inputRef.current && hasAddIcon) {
        itemsCount > 10 && !userRole.is_superuser && !userRole.is_traffic
          ? addNotification({
              title: t('PetProfilePage.tabs.gallery'),
              text: t(
                !!eventId
                  ? 'PetProfilePage.notifications.eventPhotoLimit'
                  : 'PetProfilePage.notifications.galleryLimit',
              ),
              type: NotificationType.Warning,
            })
          : inputRef.current.click();
        return;
      }

      if (inputRef.current) {
        setCurrentImage([url, isDefault ? itemIndex - 1 : itemIndex, imageId]);
      }
    },
    [addNotification, eventId, inputRef, isDefault, setCurrentImage, t, userRole],
  );

  const defaultHandleAddPhotoClick = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }

      try {
        await checkImage(file);
      } catch (error) {
        return addNotification({
          title: t('PetProfilePage.tabs.gallery'),
          text: t('PetProfilePage.notifications.wrongImageFormat'),
          type: NotificationType.Error,
        });
      }

      setImage(URL.createObjectURL(file));
    },
    [addNotification, setImage, t],
  );

  const showShareDialog = useCallback(
    photoId => {
      popupStateUpdate({
        popup: {
          text: t('PopUp.sharePhotoAfterUpload'),
          actions: [
            {
              text: t('PopUp.no'),
              color: theme.palette.error.light,
              onClick: () => {},
            },
            {
              text: t('PopUp.yes'),
              color: theme.palette.warning.light,
              onClick: async () => {
                const { updatedPhoto } = await postShare(photoId, true);
                if (updatedPhoto) {
                  setGalleryItems(prev => {
                    const photoIndex = prev.findIndex(photo => photo.photo_id === updatedPhoto.photo_id);
                    prev.splice(photoIndex, 1, updatedPhoto);
                    if (!!removeImage) {
                      LocalStorageService.setItem(
                        LocalStorageKeys.PetGallery,
                        JSON.stringify([...prev].filter(photo => !photo.isItDefault)),
                      );
                    }
                    return [...prev];
                  });
                }
              },
            },
          ],
        },
      });
    },
    [popupStateUpdate, postShare, removeImage, setGalleryItems, t],
  );

  const handleCropAndSave = useCallback(async () => {
    if (editorRef?.current) {
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      const file = await fetch(canvas);
      const blob = await file.blob();

      const compressedBlob = await new Promise<Blob>((resolve, reject) => {
        new Compressor(blob, {
          quality: 0.6,
          convertTypes: CompressorConvertTypesArr,
          convertSize: CompressorConvertSize,
          success: compressedBlob => resolve(compressedBlob),
          error: err => reject(err),
        });
      });

      if (compressedBlob.size > imageMaxSize) {
        return addNotification({
          title: t('PetProfilePage.tabs.gallery'),
          text: t('PetProfilePage.notifications.fileTooLarge'),
          type: NotificationType.Error,
        });
      }

      const postUrl = !!eventId ? 'PET_ADD_EVENT_GALLERY_ITEM' : 'PET_ADD_GALLERY_ITEM';
      const formData = new FormData();
      formData.append('URL', blobToFile(compressedBlob, 'cropped_image.png'));
      !!eventId ? formData.append('veterinarian_id', String(eventId)) : formData.append('pet_id', petId);

      setUpdateStatus(RequestState.Pending);
      const response = await post(postUrl, formData, token);
      setImage(null);
      if (!isMounted()) {
        setUpdateStatus(RequestState.Idle);
        return;
      }
      if (response.error) {
        fetchingErrorHandler(response.error);
        setUpdateStatus(RequestState.Error);
        return;
      }
      setUpdateStatus(RequestState.Success);
      addNotification({
        title: t('PetProfilePage.tabs.gallery'),
        text: t('PetProfilePage.notifications.successAddedPhoto'),
        type: NotificationType.Success,
      });
      onImageUpload?.(response.data);
      setGalleryItems(prevItems => {
        const newItems = [...prevItems];
        newItems.splice(1, 0, !!eventId ? response.data[0] : response.data);
        return newItems;
      });

      !eventId && showShareDialog(response.data.photo_id);
    }
  }, [
    editorRef,
    eventId,
    petId,
    token,
    setImage,
    isMounted,
    addNotification,
    t,
    onImageUpload,
    setGalleryItems,
    showShareDialog,
    fetchingErrorHandler,
  ]);

  const handlePageChange = useCallback(
    (currentSlide: number, nextSlide: number) => {
      setCurrentPage(nextSlide);
      setPrevPage(currentSlide);
    },
    [setCurrentPage, setPrevPage],
  );

  const onDeletePhoto = useCallback(
    async (imageId: number, afterDelete: () => void) => {
      const response = await remove(deleteUrl || 'PET_GALLERY_DELETE', imageId, token);
      if (response.data.status === 204) {
        removeImage?.(imageId);
        if (items.length > 1) {
          handlePageChange(0, 0);
          afterDelete();
        }
      }
    },
    [deleteUrl, token, removeImage, items.length, handlePageChange],
  );

  const handleDeletePhoto = (imageId: number, afterDelete: () => void) => {
    popupStateUpdate({
      popup: {
        text: t('PopUp.deletePhoto'),
        icon: deleteIcon,
        actions: [
          {
            text: t('Contacts.placeholders.cancel'),
            color: theme.palette.warning.light,
            onClick: () => {},
          },
          {
            text: t('Contacts.placeholders.yesDelete'),
            color: theme.palette.error.light,
            onClick: () => {
              onDeletePhoto(imageId, afterDelete);
            },
          },
        ],
      },
    });
  };

  const setCurrentSlide = useCallback(
    (slide: number) => {
      const currentSlide = isDefault ? slide + 1 : isItFeed ? (slide -= slide % 4) : slide;
      const visibleSlides = currentSlider?.innerSlider?.list?.querySelectorAll('.slick-slide.slick-active').length;
      const isSlideHidden = currentSlider?.innerSlider?.list
        ?.querySelector(`.slick-slide[data-index="${currentSlide}"]`)
        ?.getAttribute('aria-hidden');
      const needToChangeSlide =
        slidesToShow &&
        visibleSlides &&
        (isSlideHidden === 'true' || visibleSlides < slidesToShow) &&
        items.length >= slidesToShow;
      needToChangeSlide && currentSlider?.slickGoTo(currentSlide);
    },
    [isDefault, isItFeed, currentSlider, slidesToShow, items.length],
  );

  const startSlideClick = useCallback((event: MouseEvent<HTMLImageElement>) => {
    if (
      (event.target instanceof HTMLImageElement || event.target instanceof HTMLSpanElement) &&
      !event.target.classList.contains('sliderContainerSlideImage')
    ) {
      return;
    }
    setClickStart(event.clientX);
  }, []);

  const endSlideClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (!clickStart || event.currentTarget.querySelector('img')?.style.display === 'none') return;
      const isScroll = Math.abs(event.clientX - clickStart) > 5;
      if (!isScroll) {
        const slideId = event.currentTarget.getAttribute('data-slide-id');
        if (slideId) {
          const targetSlide = items[Number(slideId)];
          handleImageClick({
            hasAddIcon: targetSlide.isItDefault,
            itemIndex: Number(slideId),
            url: targetSlide.URL,
            imageId: targetSlide.photo_id,
            itemsCount: items.length,
          });
        }
      }
      setClickStart(0);
    },
    [clickStart, handleImageClick, items],
  );

  const onLikeClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const slideId = event.currentTarget.getAttribute('data-slide-id');
      if (slideId) {
        const targetSlide = items[Number(slideId)];
        handleImageClick({
          hasAddIcon: targetSlide.isItDefault,
          itemIndex: Number(slideId),
          url: targetSlide.URL,
          imageId: targetSlide.photo_id,
          itemsCount: items.length,
        });
      }
    },
    [handleImageClick, items],
  );

  const handleCancel = useCallback(() => setImage(null), [setImage]);

  return {
    defaultHandleAddPhotoClick,
    handleImageClick,
    updateStatus,
    handlePageChange,
    handleDeletePhoto,
    setCurrentSlide,
    startSlideClick,
    endSlideClick,
    onLikeClick,
    handleCropAndSave,
    handleCancel,
  };
}
