import { ArrowWrapper, ContentWrapper, LoadIcon } from './styledComponents';
import type { TFeedContentProps, TSliderItem } from '../../../shared/types';

import { ContentCard } from '../ContentCard/ContentCard';
import { FeedSlider } from '../FeedSlider/FeedSlider';
import { FullScreenGallery } from '../../../shared/components/FullScreenGallery/FullScreenGallery';
import arrowUp from '../../../../../assets/images/dashboard/feed_arrow.svg';
import loadIcon from '../../../../../assets/images/dashboard/loading.svg';
import { useChooseDateFormat } from '../../../../../utils/hooks/useChooseDateFormat';
import { useContentData } from './hooks/useContentData';
import { useState } from 'react';

export const FeedContent = ({
  feeds,
  popular,
  imageSize,
  needToFetchMore,
  loadMoreFeeds,
  setOpacity,
  pageRef,
  refreshFeed,
  setContent,
}: TFeedContentProps) => {
  const [currentImage, setCurrentImage] = useState<[string, number, number] | []>([]);
  const { loaderState, firstRef, lastRef, showArrow, scrollToTop } = useContentData({
    pageRef,
    needToFetchMore,
    loadMoreFeeds,
    setOpacity,
    refreshFeed,
    feeds,
  });
  const { is12HourFormat } = useChooseDateFormat();

  return (
    <ContentWrapper>
      <FeedSlider items={popular} imageSize={imageSize} onRefresh={refreshFeed} />
      <LoadIcon src={loadIcon} loaderState={loaderState} />
      {feeds.map(
        (item: TSliderItem, index: number) =>
          !!item?.is_shared && (
            <ContentCard
              key={index}
              feedItem={item}
              currentRef={index === feeds.length - 1 ? lastRef : index === 0 ? firstRef : null}
              index={index}
              openPhoto={setCurrentImage}
              setContent={setContent}
              is12HourFormat={!!is12HourFormat}
            />
          ),
      )}
      {!!currentImage?.length && (
        <FullScreenGallery
          currentImage={currentImage}
          setCurrentImage={setCurrentImage}
          gallery={feeds}
          setFeedValue={setContent}
          needToShowLikes
        />
      )}
      {showArrow && (
        <ArrowWrapper>
          <img onClick={scrollToTop} src={arrowUp} alt='arrow-up' />
        </ArrowWrapper>
      )}
    </ContentWrapper>
  );
};
