import { Box } from '@mui/material';
import { PageLoader } from '../../../shared';
import { RequestState } from '../../../../../utils/enums';
import { SliderImage } from '../../../shared/components/SliderImage/SliderImage';
import deleteIcon from '../../../../../assets/images/dashboard/delete_pet_icon.svg';
import hideIcon from '../../../../../assets/images/close_pass_blue.svg';
import saveIcon from '../../../../../assets/images/save_icon.svg';
import unHideIcon from '../../../../../assets/images/open_pass.svg';
import { useUserManipulation } from '../../../../../utils/hooks';

export function DeleteButton({
  initialHideStatus,
  onSaveClick,
}: {
  initialHideStatus: boolean;
  onSaveClick: () => void;
}) {
  const { userRequestState, handleDeleteUser, handleChangeUserStatus, isUserHidden } = useUserManipulation({
    initialHideStatus,
  });
  return (
    <Box width='100%' display='flex' justifyContent='space-between' alignItems='center'>
      <SliderImage url={deleteIcon} imageSize={32} onImgClick={handleDeleteUser} variant='circular' />{' '}
      <Box display='flex' gap={4} alignItems='center'>
        <SliderImage
          url={isUserHidden ? unHideIcon : hideIcon}
          imageSize={48}
          onImgClick={handleChangeUserStatus}
          variant='circular'
        />
        <SliderImage url={saveIcon} imageSize={32} onImgClick={onSaveClick} variant='circular' />
      </Box>
      {userRequestState === RequestState.Pending && <PageLoader />}
    </Box>
  );
}
