import { AppHeader, Portal } from '../../shared';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../MUITheme';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  popupBox: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    maxWidth: '75rem',
    margin: '0 auto',
    backgroundColor: theme.palette.text.primary,
    zIndex: 100,
    overflow: 'hidden scroll',
  },
  link: {
    textDecorationColor: theme.palette.text.primary,
  },
});

export function PoliticLink() {
  const theme = useTheme();
  const { t } = useTranslation();

  const [isPoliticOpen, setIsPoliticOpen] = useState(false);
  const classes = useStyles();

  const togglePoliticPage = useCallback(() => {
    setIsPoliticOpen(isPoliticOpen => !isPoliticOpen);
  }, []);

  return (
    <>
      <Typography>
        {t('signupPage.terms.text')}
        <Link className={classes.link} color={theme.palette.text.primary} onClick={togglePoliticPage}>
          {t('signupPage.terms.link')}
        </Link>
      </Typography>
      {isPoliticOpen && (
        <Portal>
          <Box className={classes.popupBox}>
            <AppHeader goBack={togglePoliticPage} title={t('signupPage.terms.title')} />
            <Stack p={2} pt={10} overflow='scroll'>
              1. Ми робимо все необхідне для захисту персональних даних наших Користувачів та хочемо щоб Ви знали які
              дані ми збираємо і використовуємо. Дані Умови діють для всіх інтернет-сервісів (надалі «Сервіси», а кожен
              окремо — «Сервіс»), стосовно використання вашої особистої інформації, яка збирається протягом відвідання
              сайту petslife.app.
              <br />
              <br />
              2. Ці Умови є договором приєднання, використання Користувачем Сервісів свідчить про приєднання Користувача
              до даної Угоди повністю та підтверджує його згоду з наведеними нижче умовами.
              <br />
              <br />
              3. Ми здійснюємо обробку персональних даних Користувача будь-якими способами з метою належного надання
              Користувачу послуг (ідентифікація, аутентифікація, авторизація, відновлення паролю, відповідей на запити,
              скарги та листи Користувача, а також для інших дій, що необхідні для належного надання послуг Сервісів).
              <br />
              <br />
              4. Під час використання Користувачем сайту будь-яких Сервісів Ми здійснюємо збір та обробку даних
              Користувача, а саме: — даних, що надаються Користувачем як при заповненні реєстраційних форм Сервісів, так
              і у процесі використання Сервісів; — файли cookie; — ір-адреси; — параметри та налаштування
              інтернет-браузерів.
              <br />
              <br />
              4.1. В розділі «Підтримка» на панелі інструментів більшості інтернет-браузерів Ви зможете ознайомитися з
              інформацією, як запобігти прийняттю нових «cookies» Вашим браузером або як налаштувати браузер, щоб завжди
              відхиляти «cookies». Якщо «cookies» будуть відключені, Користувач може не отримати доступ до важливих
              функцій або відмінних рис нашого сайту і використання сайту може бути обмеженим. Маркери звертань, які
              використовуються на сайті, можуть бути пов’язані з персональними даними користувача.
              <br />
              <br />
              5. Ми включаємо дані Користувача до баз персональних даних користувачів сайту з моменту, коли Користувач
              вперше починає використовувати Сервіс, а також постійно увесь період, протягом якого Користувач
              використовує будь який Сервіс. Строк зберігання даних становить період, протягом якого Користувач
              використовує будь який Сервіс.
              <br />
              <br />
              6. Під час використання Користувачем Сервісів, на інтернет-сторінках Сервісів можуть бути присутні коди
              інтернет-ресурсів третіх осіб, у результаті чого такі треті особи отримують дані, зазначені у статті 4
              даної Угоди. Такими інтернет-ресурсами третіх осіб є:
              <br />
              <br />
              6.1. системи зі збору статистики відвідувань Сервісів (Google Analytics);
              <br />
              <br />
              6.2. соціальні плагіни (блоки) соціальних мереж (наприклад, Facebook тощо);
              <br />
              <br />
              6.3. системи банеропоказів;
              <br />
              <br />
              6.4. інші ресурси.
              <br />
              <br />
              7. Ми будемо докладати зусилля, для того щоб інформація яку Ви надаєте використовувалась для цілей
              зазначених на нашому сайті (залишалась конфіденційною) та використовуємо знеособлені дані для цільового
              надання рекламних та/або інформаційних матеріалів за віком, статтю, іншими даними; для проведення
              статистичних досліджень; будь-яких інших цілей відповідно до мети проведення діяльності нами.
              <br />
              <br />
              8. Ми маємо право передати персональні дані, базу персональних даних, до якої включені персональні дані
              Користувача, повністю або частково третім особам без повідомлення про це Користувача у наступних випадках:
              особам, у ведення, володіння або власність яких передано Сервіс; особам, що є пов’язаними/афілійованими з
              нами; новому власнику та/або нашого Сервісу для обробки з метою, передбаченою даною Угодою; іншим
              користувачам Сервісів (як фізичним, так і юридичним особам), якщо на Сервісі передбачено відповідний
              функціонал та це не суперечить положенням Закону України «Про захист персональних даних».
              <br />
              <br />
              9. Користувач має усі права щодо захисту його персональних даних, які передбачено чинним законодавством
              України, зокрема статтею 8 Закону України «Про захист персональних даних» а саме:
              <br />
              9.1. Особисті немайнові права на персональні дані, які має кожна фізична особа, є невід’ємними і
              непорушними.
              <br />
              9.2. Суб’єкт персональних даних має право:
              <br />
              9.2.1. знати про місцезнаходження персональних даних, яка містить його персональні дані, її призначення та
              найменування, місцезнаходження та/або місце проживання (перебування) володільця чи розпорядника
              персональних даних або дати відповідне доручення щодо отримання цієї інформації уповноваженим ним особам,
              крім випадків, встановлених законом;
              <br />
              9.2.2. отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про
              третіх осіб, яким передаються його персональні дані;
              <br />
              9.2.3. на доступ до своїх персональних даних;
              <br />
              9.2.4. отримувати не пізніш як за тридцять календарних днів з дня надходження запиту, крім випадків,
              передбачених законом, відповідь про те, чи зберігаються його персональні дані у відповідній базі
              персональних даних, а також отримувати зміст його персональних даних, які зберігаються;
              <br />
              9.2.5. пред’являти вмотивовану вимогу персональних даних із запереченням проти обробки своїх персональних
              даних;
              <br />
              9.2.6. пред’являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних будьяким
              володільцем та розпорядником персональних даних, якщо ці дані обробляються незаконно чи є недостовірними;
              <br />
              9.2.7. на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення,
              пошкодження у зв’язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист
              від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної
              особи;
              <br />
              9.2.8. звертатися із скаргами на обробку своїх персональних даних до органів державної влади та посадових
              осіб, до повноважень яких належить забезпечення захисту персональних даних, або до суду;
              <br />
              9.2.9. застосовувати засоби правового захисту в разі порушення законодавства про захист персональних
              даних;
              <br />
              9.2.10. вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання
              згоди;
              <br />
              9.2.11. відкликати згоду на обробку персональних даних;
              <br />
              9.2.12. знати механізм автоматичної обробки персональних даних;
              <br />
              9.2.13. на захист від автоматизованого рішення, яке має для нього правові наслідки.
              <br />
              <br />
              10. Обробка персональних даних здійснюється у дата-центрах, де розміщується обладнання, що забезпечує
              функціонування Сервісів. Ми приймаємо усі передбачені законодавством України заходи для захисту
              персональних даних Користувача, зокрема, обробка персональних даних здійснюється на обладнанні, що
              міститься у приміщеннях із обмеженим доступом.
              <br />
              <br />
              11. Ми маємо право змінювати умови даної Угоди в односторонньому порядку. У цьому випадку усі зміни будуть
              опубліковані за цією адресою і будуть обов’язковими для Користувача через 10 днів від дня такої
              публікації.
              <br />
              <br />
              12. Використовуючи сайт petslife.app, Ви погоджуєтесь з цими умовами конфіденційності. У разі, якщо Ви не
              згодні з умовами конфіденційності, будь ласка, не користуйтеся Сервісами сайту petslife.app.
            </Stack>
          </Box>
        </Portal>
      )}
    </>
  );
}
