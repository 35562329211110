import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../../../utils/atoms';
import { RequestState } from '../../../../../../../utils/enums';
import { setAuthToken } from '../../../../../../../utils/services';
import { updateLocalStorageAfterAuthData } from '../../../../../authentication/utils/hooks/useUpdateLocalStorageAfterAuthData';
import { useGoogleLogin } from '../../hooks/useGoogleLogin';
import { TokenResponse } from '@react-oauth/google';

export function useHandlers(): {
  stateStatus: RequestState;
  onSuccess: (credentialResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => Promise<void>;
  onFailure: (error: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => void;
} {
  const { stateStatus, onGoogleLogin } = useGoogleLogin();

  const [, setToken] = useRecoilState(tokenState);
  const history = useHistory();

  const onSuccess = useCallback(
    async (credentialResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
      const credential = credentialResponse;
      if (!credential) {
        console.error('Credential is missing');
        return;
      }

      const body = {
        access_token: credential.access_token,
      };

      const user = await onGoogleLogin(body);
      if (stateStatus === RequestState.Error) {
        return;
      } else {
        const { access, refresh } = user.data;
        setToken((): { access: string; refresh: string } => ({ access, refresh }));
        setAuthToken(access);
        updateLocalStorageAfterAuthData(user.data, history);
      }
    },
    [onGoogleLogin, setToken, history, stateStatus],
  );

  const onFailure = useCallback((error): void => {
    console.log('FAILURE: ', error);
  }, []);

  return { stateStatus, onSuccess, onFailure };
}
