import { Redirect, Switch } from 'react-router-dom';

import { AuthRoutePath } from './utils/const/route-path';
import { ForgotPasswordPage } from './views/Forgot/ForgotPasswordPage/ForgotPasswordPage';
import { LoginPage } from './views/Login/LoginPage';
import { NewPasswordPage } from './views/Forgot/NewPasswordPage/NewPasswordPage';
import type { ReactElement } from 'react';
import RouteGuard from '../shared/components/RouteGuard';
import { SignupPage } from './views/Signup/SignupPage';
import { VerifyPhoneCode } from './views/VerifyPhoneCode/VerifyPhoneCode';
import { VerifyPhonePage } from './views/VerifyPhone/VerifyPhonePage';
import { useErrorHandler } from 'react-error-boundary';
import { PrivacyPolicy } from './views/PrivacyPolicy/PrivacyPolicy';

type PropsType = { url: string };

const AuthRoutes = ({ url }: PropsType): ReactElement => {
  const handleError = useErrorHandler();
  !navigator.onLine && handleError({ response: { status: 504 } });
  return (
    <Switch>
      <RouteGuard path={`${url}/${AuthRoutePath.LOGIN}`} Component={LoginPage} />
      <RouteGuard path={`${url}/${AuthRoutePath.FORGOT}`} Component={ForgotPasswordPage} />
      <RouteGuard path={`${url}/${AuthRoutePath.SIGNUP}`} Component={SignupPage} />
      <RouteGuard path={`${url}/${AuthRoutePath.PHONE_VERIFY}`} Component={VerifyPhonePage} />
      <RouteGuard path={`${url}/${AuthRoutePath.CODE_VERIFY}`} Component={VerifyPhoneCode} />
      <RouteGuard path={`${url}/${AuthRoutePath.NEW_PASSWORD}`} Component={NewPasswordPage} />
      <RouteGuard path={`${url}/${AuthRoutePath.PRIVACY}`} Component={PrivacyPolicy} />
      <Redirect from='*' to='/' />
    </Switch>
  );
};

export default AuthRoutes;
