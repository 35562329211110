import { NO_CONTENT_CLASS } from '../constants';
import { useMemo } from 'react';
import { useFetchBreed, useFetchKind } from '../../../hooks';

import type { TPetInfo } from '../../../types';
import { USER_LOCALE } from '../../../../../../utils/const';
import type { UseDataReturnValue } from './types';
import formatDateByLocale from '../../../../../../utils/helpers/formatDateByLocale';
import square_default_pet_avatar from '../../../../../../assets/images/pet/square_default_pet_avatar.svg';
import { useTranslation } from 'react-i18next';

export function useData({ petData }: { petData: TPetInfo }): UseDataReturnValue {
  const { avatar, name, kind: kindId, birth, gender, breed } = petData;
  const { t } = useTranslation();

  const { breeds, isLoading } = useFetchBreed({ kindId });
  const { kinds } = useFetchKind();

  const formattedBirth = useMemo(() => {
    return birth && formatDateByLocale(birth, USER_LOCALE);
  }, [birth]);

  const kindNameById = useMemo(() => kinds?.find(item => item.id === kindId)?.label, [kinds, kindId]);

  const breedById = useMemo(() => {
    return breeds.find(item => item.id === breed);
  }, [breeds, breed]);

  const genderHasContentClass = useMemo(() => {
    return gender ? '' : NO_CONTENT_CLASS;
  }, [gender]);

  const breedHasContentClass = useMemo(() => {
    return breedById ? '' : NO_CONTENT_CLASS;
  }, [breedById]);

  const kindName = useMemo(() => {
    return `${t('petForm.placeholders.kind')}:`;
  }, [t]);
  const genderName = useMemo(() => {
    return `${t('petForm.placeholders.gender')}:`;
  }, [t]);
  const birthdayName = useMemo(() => {
    return `${t('petForm.placeholders.birthday')}:`;
  }, [t]);
  const breedName = useMemo(() => {
    return `${t('petForm.placeholders.breed')}:`;
  }, [t]);

  const genderFieldValue = useMemo(() => {
    return gender ? t(`petForm.${gender}`) : '\u2014';
  }, [gender, t]);

  const breedFieldValue = useMemo(() => {
    return breedById ? breedById.name : '\u2014';
  }, [breedById]);

  const avatarSrc = useMemo(() => {
    return avatar || square_default_pet_avatar;
  }, [avatar]);

  return {
    avatarSrc,
    name,
    kindNameById,
    formattedBirth,
    genderHasContentClass,
    breedHasContentClass,
    genderFieldValue,
    breedFieldValue,
    kindName,
    genderName,
    birthdayName,
    breedName,
    isLoading,
  };
}
