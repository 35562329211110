import { LocalStorageKeys, RequestState } from '../../../../../../utils/enums';
import { useCallback, useEffect, useState } from 'react';

import { LocalStorageService } from '../../../../../../utils/services';
import type { TSliderItem } from '../../../../shared/types';
import { get } from '../../../../../../utils/services/request';
import { tokenState } from '../../../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useRecoilState } from 'recoil';

const cachedItems = () => {
  const cachedGallery = LocalStorageService.getItem(LocalStorageKeys.PetGallery);
  if (cachedGallery) {
    return JSON.parse(cachedGallery);
  } else {
    return [];
  }
};

export function useFetchGalleryItems({ petId }: { petId: string }) {
  const [items, setItems] = useState<TSliderItem[]>(cachedItems);
  const [token] = useRecoilState(tokenState);
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const isMounted = useIsMounted();
  const [requestState, setRequestState] = useState(RequestState.Idle);

  const addNewImageToLocalState = useCallback((newImage: TSliderItem) => {
    setItems(items => [newImage, ...items]);
  }, []);

  const removeImageFromLocalState = useCallback(
    (photoId: number) => {
      const newItems = items.filter(item => item.photo_id !== photoId);
      setItems(newItems);
      LocalStorageService.setItem(LocalStorageKeys.PetGallery, JSON.stringify(newItems));
    },
    [items],
  );

  const fetchItems = useCallback(
    async petId => {
      if (!token) return;

      !items.length && setRequestState(RequestState.Pending);
      const { data, error } = await get('PET_GALLERY', { pet_id: petId }, '', token);

      if (!isMounted()) return;

      switch (true) {
        case !!data:
          if (JSON.stringify(data) !== JSON.stringify(items)) {
            setItems(data);
            LocalStorageService.setItem(LocalStorageKeys.PetGallery, JSON.stringify(data));
          }
          setRequestState(RequestState.Success);
          break;
        case !!error:
          fetchingErrorHandler(error);
          setRequestState(RequestState.Error);
          break;
        default:
          setRequestState(RequestState.Idle);
          break;
      }
    },
    [token, items, isMounted, fetchingErrorHandler],
  );

  useEffect(() => {
    fetchItems(petId);
  }, [fetchItems, petId, token]);

  return { items, requestState, fetchItems, addNewImageToLocalState, removeImageFromLocalState };
}
