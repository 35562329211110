import { Link, Typography, useTheme } from '@mui/material';

import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../MUITheme';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AuthRoutePath } from '../utils/const/route-path';

const useStyles = makeStyles({
  link: {
    textDecorationColor: theme.palette.text.primary,
  },
});

export function PoliticLink({ onSaveValue }: { onSaveValue?: () => void }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const goToPrivacyPage = () => {
    onSaveValue?.();
    history.push(AuthRoutePath.PRIVACY);
  };

  return (
    <Typography>
      {t('signupPage.terms.text')}{' '}
      <Link className={classes.link} color={theme.palette.text.primary} onClick={goToPrivacyPage}>
        {t('signupPage.terms.link')}
      </Link>
    </Typography>
  );
}
