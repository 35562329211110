import { useCallback, useEffect, useState } from 'react';

import { get } from '../../../../utils/services/request';
import { tokenState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useRecoilState } from 'recoil';

type TBreedItem = {
  id: number;
  name: string;
  name_en: string;
};

export function useFetchBreed({ kindId, breedId }: { kindId?: number; breedId?: number }) {
  const [breeds, setBreeds] = useState<TBreedItem[]>([]);
  const [isLoading, setLoading] = useState(false);

  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [token] = useRecoilState(tokenState);

  const fetchBreeds = useCallback(
    async (kindId: number, breedId?: number) => {
      if (kindId >= 0) {
        setLoading(true);
        const { data, error } = await get(
          'PETS_BREEDS',
          { kind_id: kindId, ...(breedId && { breed_id: breedId }) },
          '',
          token,
        );
        if (error) {
          setLoading(false);
          return fetchingErrorHandler(error);
        }

        const preparedBreeds: TBreedItem[] = data.map(
          (breed: { id: number; breed_name: string; breed_name_ua: string }) => ({
            id: breed.id,
            name: breed.breed_name,
            name_uk: breed.breed_name_ua,
          }),
        );

        setBreeds(preparedBreeds);
        setLoading(false);
      }
    },
    [fetchingErrorHandler, token],
  );

  useEffect(() => {
    kindId && fetchBreeds(kindId, breedId);
  }, [breedId, fetchBreeds, kindId]);

  useEffect(() => {
    setBreeds([]);
  }, [kindId]);

  return { breeds, isLoading };
}
