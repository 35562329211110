import * as Yup from 'yup';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { passwordRegex } from '../../../../../utils/const';

export const useForgotPassValidationSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return Yup.object().shape({
      password: Yup.string()
        .required(t('validations.required'))
        .min(8, t('validations.password'))
        .matches(passwordRegex, t('validations.password_strong')),
      re_password: Yup.string()
        .required(t('validations.required'))
        .oneOf([Yup.ref('password')], t('validations.confirmPassword')),
      code: Yup.string().required('placeholder').min(6, 'placeholder'),
    });
  }, [t]);
};
