export const URLS = {
  LOGIN: 'auth/jwt/create',
  FACEBOOK_LOGIN: 'auth/facebook',
  GOOGLE_LOGIN: 'auth/google',
  REGISTRATION: 'auth/register',
  VERIFY_PHONE: 'activation/send',
  CODE_VERIFY: 'activation/verify',
  USERS: 'users',

  MESSAGES: 'users/messages',
  MESSAGES_SEEN: 'users/messages/seen',

  CITIES: 'cities',
  CITY_BY_ID: 'cities/get',
  FORGOT_PASSWORD: 'auth/password/forgot/send',
  RESET_PASS: 'auth/password/forgot/verify',
  REFRESH: 'auth/jwt/refresh',
  VERIFY: 'auth/jwt/verify',
  FRIENDS: 'friends',
  SEARCHFRIENDS: 'friends/my/search',
  SEARCHUSERS: 'friends/search',
  INVITEEXISTUSER: 'friends/invitation/send/user/exists',
  INVITENOTEXISTUSER: '/friends/invitation/send/user/does/not/exists',
  INVITATIONTOME: 'friends/invitation/to',
  INVITATIONFROMME: 'friends/invitation/from',
  SENDRESPONSEONINV: '/friends/invitation/result',
  PETS: 'pets',
  GET_PET_INFO: 'pets/info',
  GET_PET_NAME_AVATAR: 'pets/name',
  ALL_USERS_PETS: 'pets/get',
  PETS_BREEDS: 'pets/breed',
  PETS_KINDS: 'pets/kinds',
  PET_GALLERY: 'pets/gallery',
  PET_GALLERY_DELETE: 'pets/gallery/delete',
  PET_ADD_GALLERY_ITEM: 'pets/gallery/add',
  PET_SHARE: 'pets/share',
  PET_GET: 'pets/share',
  PET_ADD_EVENT_GALLERY_ITEM: 'pet_veterinarian/gallery/add',
  PET_DELETE_EVENT_GALLERY_ITEM: 'pet_veterinarian/gallery/delete',

  PET_ALLERGY: 'pet_allergy',
  PETS_ALLERGIES: 'pets/allergies',
  DEL_PET_ALLERGY: 'pet_allergy',

  PET_VACCINATION: 'pet_vaccination',
  PETS_VACCINATION: 'pets/vaccination',
  DEL_PET_VACCINATION: 'pet_vaccination',

  PET_TREATMENT: 'pet_treatment',
  PETS_TREATMENTS: 'pets/treatments',
  DEL_PET_TREATMENT: 'pet_treatment',

  PET_VETERINARIAN: 'pet_veterinarian',
  PETS_VETERINARIAN: 'pets/veterinarian',
  DEL_PET_VETERINARIAN: 'pet_veterinarian',
  PET_VETERINARIAN_GALLERY: 'pet_veterinarian/gallery/add',

  PET_SERVICES: 'pet_services',
  PETS_SERVICES: 'pets/services',
  DEL_PET_SERVICES: 'pet_services',

  PETS_EVENTS: 'pets/events',
  PETS_EVENTS_MONTH: 'pets/events/month',

  PETS_EVENT_TODAY_COUNT: 'pets/events/count',
  FORGOT: '/auth/forgot',
  PHONE_VERIFY: '/auth/phoneverify',

  FEEDBACK: 'feedback',

  PHOTO_FEED: 'photos/feed',
  PHOTO_LIKE: 'photos/feed/like',
  PHOTO_SHARE: 'photos/feed/share',
  PHOTO_TOP: 'photos/feed/top',
  PHOTO_BAN: 'photos/feed/ban',

  DELETE_USER: 'delete_user',
  HIDE_USER: 'hide_user',
  UNHIDE_USER: 'unhide_user',
  SAVE_LOG: 'save-log',
};

export const API_URL = process.env.REACT_APP_API_URL || 'https://api.pets.kruts.net/api/v1';
