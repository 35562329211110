import {
  AppHeader,
  CustomDateInput,
  CustomInput,
  CustomSimpleSelect,
  DeleteProfileButton,
  FormAvatar,
  FormOutdoorSchedule,
  PageLoader,
  RadioButtonGroup,
  WithFooterMenu,
} from '../../../../shared';
import { FastField, Field, Form, useFormikContext } from 'formik';
import type { TPetEditForm, TPetEditFormProps } from '../types';
import { useEffect, useMemo, useState } from 'react';

import { CustomSearchSelect } from '../../../../shared/components/CustomSearchSelect/CustomSearchSelect';
import { PetFormFields } from '../../../utils/enums/formFields';
import { PetProfileRoutePath } from '../../../../../../utils/enums/PetProfileRoutePath';
import { SelectTypes } from '../../../../../../utils/enums';
import { Stack } from '@mui/material';
import default_pet_avatar from '../../../../../../assets/images/pet/default_pet_avatar.svg';
import { isEqual } from 'lodash';
import saveIcon from '../../../../../../assets/images/save_icon.svg';
import { useData } from '../../../hooks/useData';
import { useHandleHideErrors } from '../../../../../../utils/hooks/useHandleHideErrors';
import { usePetForm } from '../hooks';
import { usePopUpHandlers } from '../../../../shared/hooks';
import { useTranslation } from 'react-i18next';

export function EditPetForm({ title, initialAvatar, requestState, kinds }: TPetEditFormProps) {
  const { errors, touched, values, initialValues, setErrors, setFieldValue, handleSubmit } =
    useFormikContext<TPetEditForm>();
  const [popUpShow, setPopUpShow] = useState(false);
  const [isFirstChange, setIsFirstChange] = useState(true);
  const { t } = useTranslation();

  const petId = values.id;

  const { animalIdOptions, handleClearField, handleDateChange, onIdTypeChange } = usePetForm();
  const { handleHideErrors } = useHandleHideErrors<TPetEditForm>({ errors, setErrors });
  const { handleBackButtonClick } = usePopUpHandlers({
    popUpShow,
    prevUrl: `${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.MAIN}`,
  });

  const {
    selectLivingPlaceOptions,
    selectTemperamentOptions,
    genderOptions,
    foodOptions,
    sterilizationOptions,
    breeds,
    setFetchBreedsArgs,
    isLoading,
  } = useData();

  const initialBreedName = useMemo(() => {
    const breedId = initialValues.breed;

    if (!breedId || !breeds.length || touched.kind) return '';

    const currentBreedObj = breeds.find(breed => {
      return breed.id === Number(breedId);
    });
    return currentBreedObj?.name || '';
  }, [breeds, initialValues.breed, touched.kind]);

  useEffect(() => {
    setPopUpShow(!isEqual(values, initialValues));
  }, [initialValues, values]);

  useEffect(() => {
    const newFetchBreedsArgs = { kindId: values.kind };
    if (initialValues.kind !== values.kind) {
      setFieldValue(PetFormFields.BREED, '');
      setFetchBreedsArgs(newFetchBreedsArgs);
      setIsFirstChange(false);
    }
    if (initialValues.kind === values.kind && !isFirstChange) {
      setFieldValue(PetFormFields.BREED, '');
      setFetchBreedsArgs(newFetchBreedsArgs);
    }
  }, [initialValues.kind, isFirstChange, setFetchBreedsArgs, setFieldValue, values.kind]);

  useEffect(() => {
    setFetchBreedsArgs({ kindId: initialValues.kind });
    //only for initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.kind]);

  return (
    <WithFooterMenu popUpShow={popUpShow}>
      <Form noValidate>
        {isLoading && <PageLoader />}
        <Stack overflow='auto'>
          <AppHeader
            goBack={handleBackButtonClick}
            title={title}
            icon={saveIcon}
            iconHandler={popUpShow ? handleSubmit : handleBackButtonClick}
          />
          <Stack padding='0rem 1rem'>
            <Stack alignItems='center' marginBottom='3.5rem' marginTop='5rem'>
              <FastField
                id={PetFormFields.AVATAR}
                name={PetFormFields.AVATAR}
                defaultSrc={initialAvatar || default_pet_avatar}
                component={FormAvatar}
                requestStatus={requestState}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                id={PetFormFields.KIND}
                name={PetFormFields.KIND}
                component={CustomSimpleSelect}
                selectType={SelectTypes.PopUpSelect}
                required
                choices={kinds}
                errors={touched.kind && errors.kind}
                label={t('petForm.placeholders.kind')}
                errorType='transparent'
                value={initialValues.kind}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                id={PetFormFields.NAME}
                name={PetFormFields.NAME}
                component={CustomInput}
                clear={handleClearField}
                required
                errors={touched.name && errors.name}
                label={t('petForm.placeholders.name')}
                errorType='transparent'
                handleHideErrors={handleHideErrors}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                id={PetFormFields.BIRTH}
                name={PetFormFields.BIRTH}
                component={CustomDateInput}
                minDateSelector={30}
                maxDate={0}
                required
                onChange={handleDateChange}
                errors={touched.birth && errors.birth}
                label={t('petForm.placeholders.birthday')}
                errorType='transparent'
                value={values.birth}
                handleHideErrors={handleHideErrors}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <Field
                key={`${values.kind}${initialBreedName}`}
                name={PetFormFields.BREED}
                component={CustomSearchSelect}
                label={t('petForm.placeholders.breed')}
                title={t('petForm.placeholders.breed')}
                options={breeds}
                value={values.breed}
                initialValue={initialBreedName}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                id={PetFormFields.GENDER}
                name={PetFormFields.GENDER}
                component={RadioButtonGroup}
                options={genderOptions}
                label={t('petForm.placeholders.gender')}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                id={PetFormFields.COLOR}
                name={PetFormFields.COLOR}
                component={CustomInput}
                clear={handleClearField}
                label={t('petForm.placeholders.color')}
                errors={touched.color && errors.color}
                handleHideErrors={handleHideErrors}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                id={PetFormFields.SPECIAL_SIGNS}
                name={PetFormFields.SPECIAL_SIGNS}
                component={CustomInput}
                clear={handleClearField}
                errors={touched.special_signs && errors.special_signs}
                label={t('petForm.placeholders.special_signs')}
                handleHideErrors={handleHideErrors}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                id={PetFormFields.FOOD}
                name={PetFormFields.FOOD}
                component={RadioButtonGroup}
                label={t('petForm.placeholders.food')}
                options={foodOptions}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                id={PetFormFields.STERILIZATION}
                name={PetFormFields.STERILIZATION}
                component={RadioButtonGroup}
                label={t('petForm.placeholders.sterilization')}
                options={sterilizationOptions}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                id={PetFormFields.LIVING_PLACE}
                name={PetFormFields.LIVING_PLACE}
                component={CustomSimpleSelect}
                choices={selectLivingPlaceOptions}
                label={t('petForm.placeholders.living_place')}
                value={initialValues.living_place}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                name={PetFormFields.OUTDOOR_SCHEDULE}
                component={FormOutdoorSchedule}
                label={t('petForm.placeholders.outdoor_schedule')}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <Field
                id={PetFormFields.ID_CHIP_STIGMA}
                name={PetFormFields.ID_TYPE}
                label={t('petForm.placeholders.animal_id')}
                options={animalIdOptions}
                component={RadioButtonGroup}
                onChange={onIdTypeChange}
                isInColumn
                handleHideErrors={handleHideErrors}
              />
            </Stack>
            <Stack marginBottom='1.5rem'>
              <FastField
                id={PetFormFields.TEMPERAMENT}
                name={PetFormFields.TEMPERAMENT}
                component={CustomSimpleSelect}
                choices={selectTemperamentOptions}
                label={t('petForm.placeholders.temperament')}
                value={initialValues.temperament}
              />
            </Stack>
            <Stack marginBottom='5rem'>
              <DeleteProfileButton petId={petId} />
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </WithFooterMenu>
  );
}
