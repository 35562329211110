import firebase from 'firebase';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY || 'AIzaSyB8qpXB3jmVYPGvpvXcukwz8GpZfXE9mKs',
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAINS || 'pets-dev-59ab1.firebaseapp.com',
  projectId: process.env.REACT_APP_FB_PROJ_ID || 'pets-dev-59ab1',
  storageBucket: process.env.REACT_APP_FB_STOR_BUCKET || 'pets-dev-59ab1.appspot.com',
  messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID || '427769665780',
  appId: process.env.REACT_APP_FB_APP_ID || '1:427769665780:web:a7e673362922f3893226a1',
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID || 'G-TCVQJ2FZYF',
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export { auth, firebase };
