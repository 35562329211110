import { atom } from 'recoil';
import type { TSignupForm } from '../../types/types';

const initialSignupValues = {
  phone: '',
  code: '',
  name: '',
  email: '',
  password: '',
  re_password: '',
  terms: false,
  recaptcha_token: null,
};

export const signupFormAtom = atom<TSignupForm>({
  key: 'signupFormAtom',
  default: initialSignupValues,
});
