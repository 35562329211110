import { Box, styled } from '@mui/material';

import { theme } from '../../../../../MUITheme';

export const StyledContainer = styled(Box)({
  margin: '3.5rem 0 0',
  paddingBottom: '3.5rem',
  width: '100%',
  backgroundColor: theme.palette.secondary.light,
  minHeight: 'calc(var(--app-height) - 7rem)',
});
