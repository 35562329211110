import * as Yup from 'yup';

import { phoneRegex } from '../../../../../utils/const';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useLoginValidationSchema = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return Yup.object().shape({
      phone: Yup.string()
        .required(t('validations.required'))
        .min(7, t('verifyPhonePage.errors.incorrectData'))
        .max(10, t('verifyPhonePage.errors.incorrectData'))
        .matches(phoneRegex, t('validations.phone_number')),
      password: Yup.string().required(t('validations.required')).min(8, t('validations.password')),
      code: Yup.string().required(t('validations.code')),
    });
  }, [t]);
};
