import { Avatar, Skeleton, Typography } from '@mui/material';
import {
  StyledCard,
  StyledCardActions,
  StyledCardMedia,
  StyledHeader,
  StyledLikeButton,
  StyledShareButton,
} from './styledComponents';

import { ComplainIcon } from '../../../shared/components/ComplainIcon/ComplainIcon';
import { HideIcon } from '../../../shared/components/HideIcon/HideIcon';
import { PhotoShareIcon } from '../../../shared/components/PhotoShareIcon/PhotoShareIcon';
import { SliderImage } from '../../../shared/components/SliderImage/SliderImage';
import type { TContentCardProps } from '../../../shared/types';
import defaultAvatar from '../../../../../assets/images/default_avatar.svg';
import { getFeedDateString } from '../../../../../utils/helpers/dateHelper';
import likeIcon from '../../../../../assets/images/dashboard/feed_red_heart.svg';
import { theme } from '../../../../../MUITheme';
import transparentLikeIcon from '../../../../../assets/images/like_white.svg';
import { useHandlers } from '../../hooks/useHandlers';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { userRoleState } from '../../../../../utils/atoms';

function reduceCount(likeCount: number): string {
  switch (true) {
    case likeCount >= 1000000000:
      return (likeCount / 1000000).toFixed(1) + 'B';
    case likeCount >= 1000000:
      return (likeCount / 1000000).toFixed(1) + 'M';
    case likeCount >= 1000:
      return (likeCount / 1000).toFixed(1) + 'K';
    default:
      return likeCount.toString();
  }
}

const LikeSkeleton = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      display={isLoading ? 'block' : 'none'}
      fill={theme.palette.primary.light}>
      <path d='M16.5436 4C13.5005 4 12.0005 7 12.0005 7C12.0005 7 10.5005 4 7.45735 4C4.98423 4 3.02579 6.06906 3.00048 8.53797C2.94892 13.6628 7.06595 17.3073 11.5786 20.3702C11.703 20.4548 11.85 20.5001 12.0005 20.5001C12.151 20.5001 12.2979 20.4548 12.4224 20.3702C16.9345 17.3073 21.0516 13.6628 21.0005 8.53797C20.9752 6.06906 19.0167 4 16.5436 4Z' />
    </svg>
  );
};

export const ContentCard = ({
  feedItem,
  currentRef,
  index,
  openPhoto,
  setContent,
  is12HourFormat,
  onRefresh,
}: TContentCardProps) => {
  const { shared_date, URL, pet_name, likes, is_liked, photo_id, is_owner, avatar, is_shared } = feedItem;
  const dateTitle = getFeedDateString(is12HourFormat, !!shared_date ? shared_date : '');

  const { handleLikeClick, handleOpenImage, isLoading, onLoad } = useHandlers({
    is_liked,
    photo_id,
    URL,
    index,
    openPhoto,
    setContent,
  });

  const imageStyle = { display: isLoading ? 'none' : 'unset' };
  const [userRole] = useRecoilState(userRoleState);
  const isHideIconShown = useMemo(() => {
    return (userRole.is_superuser || userRole.is_moderator) && !is_owner;
  }, [is_owner, userRole]);

  return (
    <>
      {!is_shared ? null : (
        <StyledCard className='styledCard' ref={currentRef}>
          <StyledHeader
            avatar={
              <Avatar>
                <SliderImage url={avatar || defaultAvatar} isLoading={isLoading} imageSize={36} />
              </Avatar>
            }
            title={isLoading ? <Skeleton animation='wave' height='1.25rem' width='80%' /> : pet_name}
            subheader={isLoading ? <Skeleton animation='wave' height='1.25rem' width='40%' /> : dateTitle}
          />
          {!is_owner && (
            <ComplainIcon photoLink={URL} size={25} top={-35} right={10} isLoading={isLoading} hasWhiteBackground />
          )}
          <StyledCardMedia>
            {isLoading && <Skeleton animation='wave' variant='rectangular' width='100%' />}
            <img
              src={URL as string}
              onClick={handleOpenImage}
              alt='feed_item_photo'
              onLoad={onLoad}
              style={imageStyle}
            />
          </StyledCardMedia>
          <StyledCardActions>
            <StyledLikeButton onClick={handleLikeClick}>
              <SliderImage
                url={is_liked ? likeIcon : transparentLikeIcon}
                isLoading={isLoading}
                SkeletonComponent={LikeSkeleton}
              />
            </StyledLikeButton>
            {!!likes && !isLoading && <Typography>{reduceCount(likes)}</Typography>}
            {(is_owner || isHideIconShown) && (
              <StyledShareButton>
                {isHideIconShown && <HideIcon photoId={photo_id ?? 0} hasWhiteBackground setContent={setContent} />}
                {is_owner && (
                  <PhotoShareIcon
                    photoId={photo_id!}
                    isShared={is_shared!}
                    size={24}
                    bottom={0}
                    right={2}
                    setFeedValue={setContent}
                    hasWhiteBackground
                    itsFeedCard
                    isLoading={isLoading}
                    onRefresh={onRefresh}
                  />
                )}
              </StyledShareButton>
            )}
          </StyledCardActions>
        </StyledCard>
      )}
    </>
  );
};
