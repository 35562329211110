import { useMemo, useCallback } from 'react';
import type { TOptions } from '../../../types';
import type { TData } from './types';

export function useData({ options, searchValue, loadedItems, setLoadedItems }: TData) {
  const filteredOptions = useMemo(() => {
    return options.filter(option => option.name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [options, searchValue]);

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const target = e.currentTarget as HTMLDivElement;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight + 500) {
        const nextPage = loadedItems.length / 50 + 1;
        const startIndex = (nextPage - 1) * 50;
        const endIndex = Math.min(startIndex + 50, filteredOptions.length);
        const newItems = filteredOptions.slice(startIndex, endIndex);

        if (newItems.length > 0) {
          setLoadedItems((prevItems: TOptions[]) => [...prevItems, ...newItems]);
        }
      }
    },
    [filteredOptions, loadedItems, setLoadedItems],
  );

  return { filteredOptions, handleScroll };
}
