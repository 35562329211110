import './VerifyPhoneCode.scss';

import { type ReactElement, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { AppModules } from '../../../../../utils/const';
import { AuthContent } from '../../components/AuthContent/AuthContent';
import type { CodeState } from '../../types/types';
import { PageLoader } from '../../../shared';
import { PhoneCode } from './_components/PhoneCode/PhoneCode';
import { RequestState } from '../../../../../utils/enums';
import { URLS } from '../../../../../utils/const';
import { hidePhone } from '../../utils/hooks/useHidePhone';
import { useHandlers } from './useHandlers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const initCodeState = {
  codeDigit1: '',
  codeDigit2: '',
  codeDigit3: '',
  codeDigit4: '',
  codeDigit5: '',
  codeDigit6: '',
};

export const VerifyPhoneCode = (): ReactElement => {
  const [code, dispatch] = useReducer(
    (s: CodeState, a: Partial<CodeState>): CodeState => ({ ...s, ...a }),
    initCodeState,
  );
  const phone = useRef(localStorage.getItem('phone_number'));
  const { verifyStatus, handleSubmit } = useHandlers(code);
  const { t } = useTranslation();
  const history = useHistory();
  const [codeError, setCodeError] = useState(false);
  const [nextEl, setNextEl] = useState('false');
  const [validateError, setValidateError] = useState(false);
  const memorizedHiddenPhone = useMemo(() => {
    if (phone.current) return hidePhone(phone.current);
  }, []);

  useEffect((): void => {
    if (verifyStatus === RequestState.Error) {
      setCodeError(true);
    } else {
      setCodeError(false);
    }
  }, [verifyStatus]);

  useEffect((): void => {
    if (nextEl !== 'false') {
      document.getElementById('hidden')?.focus();
      setNextEl('false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = (event: React.MouseEvent) => {
    event.preventDefault();
    if (Object.values(code).join('').length !== 6) {
      setValidateError(true);
      return;
    }
    handleSubmit(event);
  };
  const handleResend = (): void => {
    history.push(URLS.PHONE_VERIFY);
  };
  return (
    <>
      {phone.current ? (
        <AuthContent
          translationRootPath={'verifyPhoneCode'}
          prevUrl={URLS.PHONE_VERIFY}
          content={AppModules.codeVerify}>
          <form className='code-verify' noValidate>
            <p className='subtitle'>
              {t('verifyPhoneCode.subtitle')}
              {memorizedHiddenPhone}
            </p>
            <PhoneCode
              setNextEl={setNextEl}
              codeChange={dispatch}
              codeError={codeError}
              setCodeError={setCodeError}
              validateError={validateError}
              setValidateError={setValidateError}
            />
            {codeError && <p />}
            <button type='submit' className='submit_button' onClick={onSubmit}>
              {t('verifyPhoneCode.verify')}
            </button>
            <p className='resend'>{t('verifyPhoneCode.resendMessage')}</p>
            <button onClick={handleResend} className='resend_button'>
              <span>{t('verifyPhoneCode.resend')}</span>
            </button>
          </form>
        </AuthContent>
      ) : (
        history.push(URLS.PHONE_VERIFY)
      )}
      {verifyStatus === RequestState.Pending ? <PageLoader /> : false}
    </>
  );
};
