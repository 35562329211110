import './PetGallerySlider.scss';

import { Cropper, PageLoader } from '../../../shared';
import type { TSliderItem, TSliderProps } from '../../types';
import { useMemo, useRef, useState, useEffect } from 'react';

import AvatarEditor from 'react-avatar-editor';
import { CustomDots } from '../CustomDots/CustomDots';
import { FullScreenGallery } from '../FullScreenGallery/FullScreenGallery';
import { LikeIcon } from '../LikeIcon/LikeIcon';
import { PhotoShareIcon } from '../PhotoShareIcon/PhotoShareIcon';
import { RequestState } from '../../../../../utils/enums';
import Slider from 'react-slick';
import { SliderImage } from '../SliderImage/SliderImage';
import { calculateRem } from '../../../../../utils/helpers';
import { useSliderHandlers } from '../../hooks/useSliderHandlers';

const defaultSliderSettings = {
  swipe: true,
  draggable: true,
  arrows: false,
  touchThreshold: 100,
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 200,
  infinite: false,
  lazyLoad: 'progressive' as 'progressive',
};

export const PetGallerySlider = ({
  items,
  onImageUpload,
  imageSize,
  customSliderSettings,
  removeImage,
  deleteUrl,
  eventId,
  onRefresh,
}: TSliderProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const sliderRef = useRef<Slider>(null);
  const editorRef = useRef<AvatarEditor | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [prevPage, setPrevPage] = useState<number>(0);
  const [currentImage, setCurrentImage] = useState<[string, number, number] | []>([]);
  const [galleryItems, setGalleryItems] = useState<TSliderItem[]>(items);
  const [image, setImage] = useState<string | null>(null);
  const isDefault = !!galleryItems[0]?.isItDefault || false;
  const sliderSettings = useMemo(
    () => (!!customSliderSettings ? { ...defaultSliderSettings, ...customSliderSettings } : defaultSliderSettings),
    [customSliderSettings],
  );

  const dotsOffset = sliderSettings.slidesToShow - 1;
  const isItFeed = sliderSettings.slidesToScroll === 4;
  const needToShowLikes = sliderSettings.slidesToShow === 4;

  const {
    defaultHandleAddPhotoClick,
    updateStatus,
    handlePageChange,
    handleDeletePhoto,
    setCurrentSlide,
    startSlideClick,
    endSlideClick,
    onLikeClick,
    handleCancel,
    handleCropAndSave,
  } = useSliderHandlers({
    inputRef,
    onImageUpload,
    setCurrentPage,
    setPrevPage,
    setCurrentImage,
    isDefault,
    removeImage,
    sliderRef,
    deleteUrl,
    items: galleryItems,
    slidesToShow: sliderSettings.slidesToShow,
    isItFeed,
    setGalleryItems,
    eventId,
    setImage,
    editorRef,
  });

  const preventRightSwipe = useMemo(
    () => isItFeed && currentPage === items.length - sliderSettings.slidesToScroll,
    [currentPage, isItFeed, items.length, sliderSettings.slidesToScroll],
  );

  const SliderItems = useMemo(() => {
    sliderSettings.swipe = galleryItems.length > sliderSettings.slidesToShow;
    return (
      <Slider ref={sliderRef} {...sliderSettings} beforeChange={handlePageChange} swipeToSlide={preventRightSwipe}>
        {galleryItems.map((item, itemIndex) => {
          return (
            <div
              className='slider-item'
              key={itemIndex}
              data-slide-id={itemIndex}
              onMouseDown={startSlideClick}
              onMouseUp={endSlideClick}>
              {!!item.URL && (
                <>
                  <SliderImage
                    key={item.URL as string}
                    imageSize={imageSize}
                    url={item.URL as string}
                    customClass={`sliderContainerSlideImage ${isDefault && itemIndex === 0 ? 'default' : ''}`}
                    margin={`0 ${calculateRem(1.5)}`}
                  />
                  {needToShowLikes && (
                    <div className={!isItFeed ? '' : 'image-icons'} data-slide-id={itemIndex} onClick={onLikeClick}>
                      {!!item?.photo_id && (
                        <>
                          <LikeIcon
                            likesCount={item.likes || 0}
                            isLikedByUser={!!item.is_liked}
                            hasWhiteBackground={false}
                            photoId={item.photo_id}
                            setGalleryItems={setGalleryItems}
                            bottom={isItFeed ? 10 : 4}
                            left={6}
                            preventClick
                          />
                          {!isItFeed && !!item.is_shared && (
                            <PhotoShareIcon
                              photoId={item.photo_id!}
                              isShared={!item.is_shared}
                              bottom={4}
                              preventClick
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {isItFeed && <span className='image-title'>{item.pet_name}</span>}
                </>
              )}
            </div>
          );
        })}
      </Slider>
    );
  }, [
    endSlideClick,
    galleryItems,
    handlePageChange,
    imageSize,
    isDefault,
    isItFeed,
    needToShowLikes,
    onLikeClick,
    preventRightSwipe,
    sliderSettings,
    startSlideClick,
  ]);

  useEffect(() => {
    setGalleryItems(items);
  }, [items]);

  return (
    <div className='petSliderContainer'>
      <input
        type='file'
        accept='image/*'
        ref={inputRef}
        onChange={defaultHandleAddPhotoClick}
        className='hiddenInput'
      />
      {SliderItems}
      {updateStatus === RequestState.Pending && <PageLoader />}
      {!isItFeed && (
        <CustomDots
          key={galleryItems.length}
          pages={galleryItems.length - dotsOffset}
          selectedPage={currentPage}
          prevPage={prevPage}
        />
      )}

      {currentImage.length > 0 && (
        <FullScreenGallery
          currentImage={currentImage as [string, number, number]}
          setCurrentImage={setCurrentImage}
          gallery={galleryItems}
          onDelete={handleDeletePhoto}
          setCurrentSlide={setCurrentSlide}
          hasDeleteIcon={!!removeImage || !!deleteUrl}
          setGalleryItems={setGalleryItems}
          needToShowLikes={needToShowLikes}
          isItFeed={isItFeed}
          onRefresh={onRefresh}
        />
      )}
      {!!image && (
        <Cropper
          image={image}
          editorRef={editorRef}
          handleCancel={handleCancel}
          handleCropAndSave={handleCropAndSave}
        />
      )}
    </div>
  );
};
