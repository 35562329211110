import { RequestState } from '../enums';
import * as RequestService from './request';
import { TErrorLog } from '../types';
import { getFormattedTimestamp } from '../helpers/dateHelper';

export const debug = <T>(data: T): void => {
  console.log('DEBUG: ', data);
};

export const error = <T>(data: T): void => {
  console.log('ERROR: ', error);
};

export const logError = (error: Error) => {
  const body: TErrorLog = {
    level: 'error',
    message: { error: error.message },
    timestamp: getFormattedTimestamp(),
  };
  sendErrorLogToAPI(body);
};

//@ts-ignore
const sendErrorLogToAPI = async (body: TErrorLog): Promise<Core.RequestResponse> => {
  try {
    const response = await RequestService.post('SAVE_LOG', body, '');
    if (response.error) {
      return RequestState.Error;
    }
    return RequestState.Success;
  } catch (err) {
    return RequestState.Error;
  }
};
