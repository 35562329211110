import * as RequestService from './../../utils/services/request';

import { LocalStorageKeys, RequestState, UserGroups } from '../enums';
import { useCallback, useState } from 'react';

import { LocalStorageService } from '../services';
import { useIsMounted } from './useIsMounted';
import { useRecoilState } from 'recoil';
import { userRoleState } from '../atoms';

// @ts-ignore
export default function useGetUser(): { userStatus: RequestState; userData: () => Promise<Core.RequestResponse> } {
  const isMounted = useIsMounted();
  const token = localStorage.getItem('access') || '';
  const phone = localStorage.getItem('phone_number') || '';
  const [, setUserRole] = useRecoilState(userRoleState);
  const [userStatus, setUserStatus] = useState(RequestState.Idle);
  const userData = useCallback(
    // @ts-ignore
    async (): Promise<Core.RequestResponse> => {
      setUserStatus(RequestState.Pending);
      // @ts-ignore
      const response = await RequestService.get(`USERS`, {}, { phone }, { token });

      if (!isMounted) {
        setUserStatus(RequestState.Idle);
        return;
      }

      setUserStatus(response.error ? RequestState.Error : RequestState.Success);
      if (response.data.email) {
        LocalStorageService.setItem(LocalStorageKeys.Email, response.data.email);
      }
      if (response.data.avatar) {
        LocalStorageService.setItem(LocalStorageKeys.Avatar, response.data.avatar);
      }
      const countryLocale = response.data.country === 224 ? 'uk' : 'en';
      LocalStorageService.setItem(LocalStorageKeys.Country, countryLocale);

      setUserRole({
        is_superuser: response.data.is_superuser,
        is_moderator: response.data.groups.includes(UserGroups.Moderator),
        is_traffic: response.data.groups.includes(UserGroups.Traffic),
      });

      return response;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted],
  );

  return { userStatus, userData };
}
