export enum AuthRoutePath {
  CODE_VERIFY = 'codeverify',
  PHONE_VERIFY = 'phoneverify',
  SIGNUP = 'signup',
  FORGOT = 'forgot',
  LOGIN = 'login',
  POLICY = 'policy',
  NEW_PASSWORD = 'newpassword',
  PRIVACY = 'privacy',
}
