import {
  AM_TIME,
  DATA_BASE_FORMAT,
  EN_LOCALE_DATE_FORMAT_DAYJS,
  EN_LOCALE_TIME_FORMAT,
  FINISH_DATE_DATA_BASE_FORMAT,
  UK_LOCALE_TIME_FORMAT,
  UTC_DATA_BASE_FORMAT,
  UTC_DATA_BASE_FORMAT_Z,
  hours12InMinutes,
  minutesInHour,
  PM_TIME,
  EN_LOCALE_TIME_FORMAT_ARRAY,
  DAY,
  DAYS_FORMAT_THREE,
  MONTH,
} from '../const';
import dayjs, { isDayjs, type Dayjs } from 'dayjs';
import { format as fnsFormat } from 'date-fns';
import relativeTime from 'dayjs/plugin/relativeTime';

import type { CalendarDate } from '../types';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import objectSupport from 'dayjs/plugin/objectSupport';
import type { TAmPm } from '../../pwa/modules/shared/types';
import 'dayjs/locale/uk';
import { LocalStorageKeys, Locales } from '../enums';
import { LocalStorageService } from '../services';

const locale = LocalStorageService.getItem(LocalStorageKeys.Language);

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);
dayjs.extend(relativeTime);

dayjs.locale(locale === Locales.UK_SHORT ? Locales.UK_SHORT : Locales.EN_SHORT);

export const today = dayjs();

export function getCurrentDate() {
  return dayjs(new Date());
}
export function getCurrentTime(): string {
  const hours = dayjs().hour().toString().padStart(2, '0');
  const minutes = dayjs().minute().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export function getCurrentDay(date: CalendarDate, minDate: Dayjs, maxDate: Dayjs): CalendarDate {
  if (date && date < minDate) return dayjs(minDate);
  if (date && date > maxDate) return dayjs(maxDate);
  return dayjs(date);
}

export function getMinMaxDate(disablePast: boolean): [Dayjs, Dayjs] {
  const minDate = disablePast ? today : today.subtract(6, 'month');
  const maxDate = today.add(6, 'month');
  return [minDate, maxDate];
}

export function getTimeOffset(): string {
  const offset = dayjs().utcOffset() / minutesInHour;
  return offset.toString();
}

export function getDateWithTimeFromUTC(
  utcDate: string,
  dateFormat: string,
  timeFormat: string,
): [date: string, time: string] {
  const date = dayjs(utcDate).utc(true).format(dateFormat);
  const time = dayjs(utcDate).utc(true).format(timeFormat);
  return [date, time];
}
export function getTimeFromUTC(utcDate: string, timeFormat: string): string {
  return dayjs(utcDate).utc(true).format(timeFormat);
}

export function transformRangeDateToUTC(
  dateFrom: string | dayjs.Dayjs | null,
  dateTo: string | dayjs.Dayjs | null = dateFrom,
): [UTCStartDate: string, UTCFinishDate: string] {
  const startDate = dayjs(dateFrom).startOf(MONTH).format(DATA_BASE_FORMAT);
  const UTCStartDate = dayjs(startDate).utc().format(UTC_DATA_BASE_FORMAT).concat('Z');

  const finishDate = dayjs(dateTo || dateFrom)
    .endOf(MONTH)
    .format(FINISH_DATE_DATA_BASE_FORMAT);
  const UTCFinishDate = dayjs(finishDate).utc().format(UTC_DATA_BASE_FORMAT).concat('Z');

  return [UTCStartDate, UTCFinishDate];
}

export const getFormattedTimestamp = (): string => {
  const now = new Date();
  const date = now.toISOString().split('T')[0];
  const time = now.toTimeString().split(' ')[0];
  const milliseconds = now.getMilliseconds().toString().padStart(3, '0');
  return `${date} ${time},${milliseconds}`;
};

export function eventDateToDayjs(date: dayjs.ConfigType) {
  return dayjs(date);
}

export function transformDateToFormat(timeUnit: Dayjs | string, format: string) {
  return dayjs(timeUnit).format(format);
}

export const transformTimeToStr = (time: number): string => {
  const getPadTime = (t: number) => t.toString().padStart(2, '0');

  const hours: string = getPadTime(Math.floor(time / minutesInHour));
  const minutes: string = getPadTime(time - Number(hours) * minutesInHour);
  return `${hours}:${minutes}`;
};
export const getCurrentTimeInNumber = (is12HourFormat: boolean, time?: string): [number] | [number, TAmPm] => {
  const today = time ?? dayjs();
  const timeFormat = is12HourFormat ? EN_LOCALE_TIME_FORMAT_ARRAY : UK_LOCALE_TIME_FORMAT;
  const currentTime = dayjs(today).format(timeFormat);

  const [hours, minutes, meridiem] = currentTime.split(/[:|\s]/g);
  const numbMinutes = Number(minutes);
  const roundedMinutes = 5 - (numbMinutes % 5) + numbMinutes;
  const currentMinutes = numbMinutes % 5 === 0 ? numbMinutes : roundedMinutes;
  const timeNumber = Number(hours) * minutesInHour + currentMinutes;
  return is12HourFormat ? [timeNumber, meridiem as TAmPm] : [timeNumber];
};

export const convertTo24HourFormat = (time: [number] | [number, TAmPm | undefined]): string => {
  const [totalMinutes, meridiem] = time;
  let hours24 = Math.floor(totalMinutes / minutesInHour);
  let minutes = totalMinutes % minutesInHour;
  if (meridiem === PM_TIME && hours24 !== 12) {
    hours24 += 12;
  }
  if (meridiem === AM_TIME && hours24 === 12) {
    hours24 = 0;
  }
  const formattedTime = `${String(hours24).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

  return formattedTime;
};

export const convertNumToHours = (value: number, is12HFormat?: boolean) => {
  const formattedValue = is12HFormat && value > hours12InMinutes ? value - hours12InMinutes : value;
  const hours = Math.floor(formattedValue / minutesInHour);

  return hours < 10 ? '0' + hours : String(hours);
};

export const convertNumToMinutes = (value: number) => {
  const minutes = value % minutesInHour;

  return minutes < 10 ? '0' + minutes : String(minutes);
};

export const getTimeInNumber = (is12HourFormat: boolean, time: number): [number] | [number, TAmPm] => {
  const stringTime = transformTimeToStr(time);
  const timeWithMeridiem =
    time < hours12InMinutes ? `${stringTime} ${AM_TIME}` : `${transformTimeToStr(time - hours12InMinutes)} ${PM_TIME}`;
  const formattedTime = is12HourFormat ? timeWithMeridiem : stringTime;
  const [hours, minutes, meridiem] = formattedTime.split(/[:|\s]/g);
  const numbMinutes = Number(minutes);
  const timeNumber = Number(hours) * minutesInHour + numbMinutes;

  return is12HourFormat ? [timeNumber, meridiem as TAmPm] : [timeNumber];
};
export const transformTimeToNumb = (time: string): number => {
  const [hours, minutes, meridiem]: string[] = time.split(/[:|\s]/g);
  const resultHours = !meridiem || meridiem === AM_TIME ? Number(hours) : Number(hours) + hours12InMinutes;
  const result = resultHours * minutesInHour + Number(minutes);
  return result;
};

export const transformDateToUTC = (formDate: string | (Dayjs & number) | Dayjs, formTime: string): string => {
  const date = dayjs(formDate).format(EN_LOCALE_DATE_FORMAT_DAYJS);

  const time = dayjs(formTime, formTime.includes('m') ? EN_LOCALE_TIME_FORMAT : UK_LOCALE_TIME_FORMAT).format(
    UK_LOCALE_TIME_FORMAT,
  );
  return dayjs(`${date}T${time}`).utc().format(UTC_DATA_BASE_FORMAT_Z);
};

export function getMonthFromDate(date: Dayjs | string | null) {
  return dayjs(date).month();
}

export function formatDateToCurrentType(date: Dayjs | string) {
  return dayjs(date);
}

export function transformDayjsToDateFns(dayjsDate: Dayjs, format: string) {
  const commonDate = dayjs(dayjs(dayjsDate)).toDate();
  const fnsDate = fnsFormat(commonDate, format);
  return fnsDate;
}

export function pickerMinMaxDate(minDateSelector: number | dayjs.Dayjs, maxDate: number | dayjs.Dayjs) {
  const pickerMinDate =
    typeof minDateSelector === 'number' ? dayjs().subtract(minDateSelector, 'year') : minDateSelector;
  const pickerMaxDate = isDayjs(maxDate) ? (maxDate as Dayjs) : dayjs().subtract(maxDate, 'year');
  return { pickerMinDate, pickerMaxDate };
}

export function isDateBefore(currentDate: Dayjs, comparisonDate?: Dayjs): boolean {
  return currentDate.isBefore(comparisonDate ?? dayjs());
}

export function isToday(date: string): boolean {
  const today = getCurrentDate();
  return dayjs(date).isSame(today, DAY);
}
export function isMoreThenSevenDaysAgo(date: string, dayAgo: number): boolean {
  const today = getCurrentDate();
  const dateDiff = today.diff(dayjs(date), DAY);
  return dateDiff >= dayAgo;
}

const correctUkAbbreviation: { [key: string]: string } = {
  ндл: 'нед',
  пнд: 'пон',
  втр: 'вів',
  срд: 'сер',
  чтв: 'чет',
  птн: 'пят',
  сбт: 'суб',
};

export function getDateDependingOnHowLongAgo({
  date,
  dayFormat,
  dayAgo,
}: {
  date: string;
  dayFormat: string;
  dayAgo: number;
}): string {
  if (isToday(date)) return '';
  if (!isMoreThenSevenDaysAgo(date, dayAgo)) {
    if (locale === Locales.UK_SHORT) {
      // It is necessary to replace the abbreviations from the library
      // with the correct ones for the Ukrainian language, more details
      // in issue: https://github.com/iamkun/dayjs/issues/2631
      const defaultDayAbr = dayjs(date)
        .locale(locale ?? Locales.EN_SHORT)
        .format(DAYS_FORMAT_THREE);
      return `${correctUkAbbreviation[defaultDayAbr]?.[0].toUpperCase()}${correctUkAbbreviation[defaultDayAbr]?.slice(
        1,
      )}`;
    }
    return dayjs(date).locale(Locales.EN_SHORT).format(DAYS_FORMAT_THREE);
  }
  return dayjs(date).format(dayFormat);
}

export const getFeedDateString = (is12HourFormat: boolean, date: string) => {
  const locale = LocalStorageService.getItem(LocalStorageKeys.Language);
  dayjs.locale(locale === Locales.UK_SHORT ? Locales.UK_SHORT : Locales.EN_SHORT);
  const now = dayjs();
  const inputDate = dayjs(date);
  let result;
  const isUkLocale = locale === Locales.UK_SHORT;
  const currentFormat = is12HourFormat ? EN_LOCALE_TIME_FORMAT : UK_LOCALE_TIME_FORMAT;

  switch (true) {
    case inputDate.isSame(now, DAY):
      result = inputDate.format(currentFormat);
      break;
    case inputDate.isAfter(now.subtract(7, DAY)):
      if (locale === Locales.UK_SHORT) {
        const defaultDayAbr = dayjs(date).format(DAYS_FORMAT_THREE);
        result = `${correctUkAbbreviation[defaultDayAbr][0].toUpperCase()}${correctUkAbbreviation[defaultDayAbr].slice(
          1,
        )} ${inputDate.format(currentFormat)}`;
      } else {
        result = `${inputDate.format(DAYS_FORMAT_THREE)} ${inputDate.format(currentFormat)}`;
      }
      break;
    case inputDate.isAfter(now.subtract(15, DAY)):
      result = `2 ${isUkLocale ? 'тижні' : 'weeks'}`;
      break;
    case inputDate.isAfter(now.subtract(22, DAY)):
      result = `3 ${isUkLocale ? 'тижні' : 'weeks'}`;
      break;
    case inputDate.isAfter(now.subtract(30, DAY)):
      result = isUkLocale ? 'місяць' : 'month';
      break;
    default:
      result = isUkLocale ? 'Більше місяця' : 'More month';
      break;
  }

  return result;
};
