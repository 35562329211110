import { MAX_OUTDOOR_TIME, MIN_OUTDOOR_TIME } from '../../../../utils/const';

import { PetFields } from '../enums';
import type { TArray } from '../../shared/types';
import type { TPetForm } from '../components/PetForm/types/types';
import type { TPetInfo } from '../types';
import { sortSelectOptions } from '../../../../utils/helpers';
import { useFetchBreed } from './useFetchBreed';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const petDataState: TPetInfo = {
  avatar: '',
  birth: '',
  breed: null,
  color: '',
  food: '',
  gender: '',
  id: -1,
  id_chip_stigma: '',
  id_type: '',
  kind: -1,
  living_place: '',
  name: '',
  outdoor_schedule: '',
  owners: [],
  registration: '',
  special_signs: '',
  sterilization: '',
  temperament: '',
  has_allergy: false,
};

const KIND_SELECT_OPTIONS: TArray[] = [
  {
    id: 36,
    i18KeyName: 'selects.kind.crows',
  },
  {
    id: 35,
    i18KeyName: 'selects.kind.owls',
  },
  {
    id: 34,
    i18KeyName: 'selects.kind.parrots',
  },
  {
    id: 33,
    i18KeyName: 'selects.kind.canaries',
  },
  {
    id: 32,
    i18KeyName: 'selects.kind.amadins',
  },
  {
    id: 31,
    i18KeyName: 'selects.kind.snakes',
  },
  {
    id: 30,
    i18KeyName: 'selects.kind.lizards',
  },
  {
    id: 29,
    i18KeyName: 'selects.kind.crocodiles',
  },
  {
    id: 28,
    i18KeyName: 'selects.kind.alligators',
  },
  {
    id: 27,
    i18KeyName: 'selects.kind.turtles',
  },
  {
    id: 26,
    i18KeyName: 'selects.kind.squirrels',
  },
  {
    id: 25,
    i18KeyName: 'selects.kind.guinea_pigs',
  },
  {
    id: 24,
    i18KeyName: 'selects.kind.mice',
  },
  {
    id: 23,
    i18KeyName: 'selects.kind.rats',
  },
  {
    id: 22,
    i18KeyName: 'selects.kind.chinchillas',
  },
  {
    id: 21,
    i18KeyName: 'selects.kind.hamsters',
  },
  {
    id: 20,
    i18KeyName: 'selects.kind.anteaters',
  },
  {
    id: 19,
    i18KeyName: 'selects.kind.bears',
  },
  {
    id: 18,
    i18KeyName: 'selects.kind.lynx',
  },
  {
    id: 17,
    i18KeyName: 'selects.kind.cougars',
  },
  {
    id: 16,
    i18KeyName: 'selects.kind.panthers',
  },
  {
    id: 15,
    i18KeyName: 'selects.kind.lions',
  },
  {
    id: 14,
    i18KeyName: 'selects.kind.tigers',
  },
  {
    id: 13,
    i18KeyName: 'selects.kind.fishes',
  },
  {
    id: 12,
    i18KeyName: 'selects.kind.rabbits',
  },
  {
    id: 11,
    i18KeyName: 'selects.kind.insects',
  },
  {
    id: 10,
    i18KeyName: 'selects.kind.amphibians',
  },
  {
    id: 9,
    i18KeyName: 'selects.kind.birds',
  },
  {
    id: 8,
    i18KeyName: 'selects.kind.reptiles',
  },
  {
    id: 7,
    i18KeyName: 'selects.kind.ferrets',
  },
  {
    id: 6,
    i18KeyName: 'selects.kind.monkeys',
  },
  {
    id: 5,
    i18KeyName: 'selects.kind.foxes',
  },
  {
    id: 4,
    i18KeyName: 'selects.kind.pigs',
  },
  {
    id: 3,
    i18KeyName: 'selects.kind.horses',
  },
  {
    id: 2,
    i18KeyName: 'selects.kind.cats',
  },
  {
    id: 1,
    i18KeyName: 'selects.kind.dogs',
  },
];
const LIVING_PLACE_OPTIONS: TArray[] = [
  {
    id: 'house',
    i18KeyName: 'selects.place.house',
  },
  {
    id: 'apartment',
    i18KeyName: 'selects.place.apartment',
  },
  {
    id: 'other',
    i18KeyName: 'selects.place.other',
  },
];
const TEMPERAMENT_OPTIONS: TArray[] = [
  {
    id: 'friendly',
    i18KeyName: 'selects.temperament.friendly',
  },
  {
    id: 'unfriendly',
    i18KeyName: 'selects.temperament.unfriendly',
  },
  {
    id: 'middle',
    i18KeyName: 'selects.temperament.middle',
  },
];

const initialCreatePetForm: TPetForm = {
  avatar: undefined,
  kind: 0,
  name: '',
  birth: null,
  breed: undefined,
  gender: '',
  color: '',
  registration: null,
  special_signs: '',
  food: '',
  sterilization: 'not chosen',
  living_place: '',
  outdoor_schedule: [],
  id_type: 'no chip',
  id_chip_stigma: '',
  temperament: '',
};

type TValue = { label: string; id: string | number; src?: string; i18KeyName: string };

export function useData() {
  const [fetchBreedsArgs, setFetchBreedsArgs] = useState<{ kindId?: number; breedId?: number }>({});

  const { breeds, isLoading } = useFetchBreed(fetchBreedsArgs);

  const { t } = useTranslation();

  const selectKindOptions: TValue[] = sortSelectOptions(KIND_SELECT_OPTIONS, t).map((item: TArray) => ({
    id: item.id,
    i18KeyName: item.i18KeyName,
    label: t(item.i18KeyName),
  }));

  const selectLivingPlaceOptions: TArray[] = LIVING_PLACE_OPTIONS.map((item: TArray) => ({
    id: item.id,
    i18KeyName: item.i18KeyName,
    label: t(item.i18KeyName),
  }));
  const selectTemperamentOptions = sortSelectOptions(TEMPERAMENT_OPTIONS, t).map((item: TArray) => ({
    id: item.id,
    label: t(item.i18KeyName),
  }));

  const genderOptions = [
    { label: t('petForm.male'), value: 'male' },
    { label: t('petForm.female'), value: 'female' },
  ];

  const foodOptions = [
    { label: t('petForm.natural'), value: 'natural' },
    { label: t('petForm.mixed'), value: 'mixed' },
    { label: t('petForm.Feed'), value: 'Feed' },
    { label: t('petForm.not_chosen'), value: '' },
  ];

  const sterilizationOptions = [
    { label: t('petForm.yes'), value: 'yes', className: 'petPageFormRadioButtonGreen' },
    { label: t('petForm.no'), value: 'no', className: 'petPageFormRadioButtonRed' },
    { label: t('petForm.not_chosen'), value: 'not chosen' },
  ];

  const animalIdOptions = [
    {
      label: t('petForm.stigma'),
      value: PetFields.Stigma,
    },
    {
      label: t('petForm.chip'),
      value: PetFields.Chip,
    },
    {
      label: t('petForm.no_chip'),
      value: PetFields.NoChip,
    },
  ];

  return {
    petDataState,
    initialCreatePetForm,
    selectLivingPlaceOptions,
    selectKindOptions,
    selectTemperamentOptions,
    genderOptions,
    foodOptions,
    sterilizationOptions,
    MIN_OUTDOOR_TIME,
    MAX_OUTDOOR_TIME,
    KIND_SELECT_OPTIONS,
    animalIdOptions,
    setFetchBreedsArgs,
    breeds,
    isLoading,
  };
}
