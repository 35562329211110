import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

import { ReactComponent as GoogleIcon } from '../../../../../../../assets/images/google_logo.svg';
import type { ReactElement } from 'react';
import { useHandlers } from './useHandlers';

const clientId= process.env.REACT_APP_OAUTH_CLIENT_ID || '427769665780-cquigfq70726p74fda7nee3ejf1o5eck.apps.googleusercontent.com'

function CustomGoogleIcon(): ReactElement {
  const { onSuccess, onFailure } = useHandlers();

  const login = useGoogleLogin({
    onSuccess: credentialResponse => onSuccess(credentialResponse),
    onError: error => {
      console.log('Error:', error);
      onFailure(error);
    },
  });

  return <GoogleIcon onClick={() => login()} style={{ cursor: 'pointer' }} />;
}

export function CustomGoogleLogin(): ReactElement {
  return (
    <GoogleOAuthProvider clientId={clientId} >
      <CustomGoogleIcon />
    </GoogleOAuthProvider>
  );
}
