import { AbsoluteRoutes, RequestState } from '../../../../../utils/enums';
import { AppHeader, CustomInput, FormAvatar, PageLoader, RadioButtonGroup, WithFooterMenu } from '../../../shared';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import type { ReactElement } from 'react';
import type { TUserData } from '../../utils/types/types';
import default_avatar from '../../../../../assets/images/default_avatar.svg';
import edit_icon from '../../../../../assets/images/edit_icon.svg';
import { useData } from './useData';
import { useErrorHandler } from 'react-error-boundary';
import useGetUser from '../../../../../utils/hooks/useGetUser';
import { useGoBack } from '../../../../../utils/hooks';
import { useHandlers } from './useHandlers';
import { useTranslation } from 'react-i18next';

export const ProfileMainPage = (): ReactElement => {
  const { t } = useTranslation();
  const goBack = useGoBack(AbsoluteRoutes.DashboardMain);
  const { userStatus, userData } = useGetUser();
  const handleError = useErrorHandler();
  const [user, setUser] = useState<TUserData | undefined>();
  const { formattedBirthday, radioButtonGroupOptions, cityAndCountry, radioButtonGroupValue } = useData(user);
  const { editButtonHandler } = useHandlers();

  useEffect((): void => {
    // @ts-ignore
    userData().then((value: Core.RequestResponse<TUserData>): void => {
      setUser(value.data);
    });
  }, [handleError, userData]);

  return (
    <WithFooterMenu>
      {!user || userStatus !== RequestState.Success ? (
        <PageLoader />
      ) : (
        <Box>
          <AppHeader goBack={goBack} title={t('ProfilePage.title')} icon={edit_icon} iconHandler={editButtonHandler} />
          <Stack alignItems='center' gap='1.5rem' padding='5rem 1rem 6rem'>
            <FormAvatar defaultSrc={user?.avatar || default_avatar} alt='User' requestStatus={userStatus} disabled />
            <CustomInput
              id='first_name'
              name='first_name'
              type='text'
              readOnly
              value={user?.first_name}
              data-id='first_name'
              label={t('ProfilePage.placeholders.first_name')}
            />
            <CustomInput
              id='last_name'
              name='last_name'
              readOnly
              type='text'
              value={user?.last_name}
              data-id='last_name'
              label={t('ProfilePage.placeholders.last_name')}
            />
            <CustomInput
              id='birthday'
              name='birthday'
              readOnly
              type='text'
              value={formattedBirthday}
              data-id='last_name'
              label={t('ProfilePage.placeholders.birthday')}
            />
            <RadioButtonGroup options={radioButtonGroupOptions} name='gender' disabled field={radioButtonGroupValue} />
            <CustomInput
              id='phone_number'
              name='phone_number'
              type='text'
              className='phone_number'
              value={user?.phone_number}
              readOnly
              data-id='phone_number'
              label={t('ProfilePage.placeholders.phone_number')}
              required
            />
            <CustomInput
              id='email'
              name='email'
              type='text'
              readOnly
              value={user?.email}
              data-id='email'
              label={t('ProfilePage.placeholders.email')}
            />
            <CustomInput
              id='country'
              name='country'
              type='text'
              data-id='country'
              readOnly
              value={cityAndCountry.country}
              label={t('ProfilePage.placeholders.country')}
              required
            />
            <CustomInput
              id='city'
              name='city'
              type='text'
              data-id='city'
              readOnly
              value={cityAndCountry.city}
              label={t('ProfilePage.placeholders.city')}
            />
          </Stack>
        </Box>
      )}
    </WithFooterMenu>
  );
};
